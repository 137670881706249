import { CSSProperties } from 'react';

import { CSSProperties as MuiCSSProperties } from '@mui/styles';

type TypographySegmentType = 'header' | 'section' | 'label' | 'body' | 'link';
type TypographySizeType = 'XXL' | 'XL' | 'L' | 'M' | 'S' | 'XS';

type HeaderKeyType = `header${TypographySizeType}`;
type SectionKeyType = `section${Extract<TypographySizeType, 'L' | 'M' | 'S'>}`;
type LabelKeyType = `label${Extract<TypographySizeType, 'M' | 'S'>}`;
type BodyKeyType = `body${Extract<TypographySizeType, 'L' | 'M' | 'S' | 'XS'>}`;
type LinkKeyType = `link${Extract<TypographySizeType, 'S' | 'XS'>}`;

type TypographyKeyType = Extract<
  `${TypographySegmentType}${TypographySizeType}`,
  HeaderKeyType | SectionKeyType | LabelKeyType | BodyKeyType | LinkKeyType
>;

type TypographyPropertyType = 'fontSize' | 'lineHeight' | 'fontWeight';
type TypographyValueType = Pick<
  CSSProperties & MuiCSSProperties,
  TypographyPropertyType
>;

export const TYPOGRAPHY: Record<TypographyKeyType, TypographyValueType> =
  Object.freeze({
    headerXXL: {
      fontSize: 40,
      lineHeight: 1.1, //'44px'
      fontWeight: 700
    },
    headerXL: {
      fontSize: 28,
      lineHeight: 1.28, //'36px'
      fontWeight: 700
    },
    headerL: {
      fontSize: 24,
      lineHeight: 1.3, //'32px'
      fontWeight: 700
    },
    headerM: {
      fontSize: 18,
      lineHeight: 1.55, //'28px'
      fontWeight: 700
    },
    headerS: {
      fontSize: 16,
      lineHeight: 1.5, //'24px'
      fontWeight: 700
    },
    headerXS: {
      fontSize: 14,
      lineHeight: 1.42, //20px'
      fontWeight: 700
    },
    sectionL: {
      fontSize: 16,
      lineHeight: 1.25, //'20px'
      fontWeight: 700
    },
    sectionM: {
      fontSize: 14,
      lineHeight: 1.14, //'16px'
      fontWeight: 700
    },
    sectionS: {
      fontSize: 12,
      lineHeight: 1.33, //'16px'
      fontWeight: 700
    },
    labelM: {
      fontSize: 18,
      lineHeight: 1.5, //'28px'
      fontWeight: 500
    },
    labelS: {
      fontSize: 16,
      lineHeight: 1.5, //'16px'
      fontWeight: 500
    },
    bodyL: {
      fontSize: 18,
      lineHeight: 1.55 //'28px'
    },
    bodyM: {
      fontSize: 16,
      lineHeight: 1.5 //'24px'
    },
    bodyS: {
      fontSize: 14,
      lineHeight: 1.42 //'20px'
    },
    bodyXS: {
      fontSize: 12,
      lineHeight: 1.33 //'16px'
    },
    linkS: {
      fontSize: 16,
      lineHeight: 1.5 //'24px'
    },
    linkXS: {
      fontSize: 14,
      lineHeight: 1.42 //'20px'
    }
  });
