import React from 'react';
import { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useDeepCompareEffect, useLatest } from 'react-use';

type Props = {
  context?: LDSingleKindContext;
  onDone?: () => void;
};

export const LaunchDarklyIdentify = ({
  children,
  context,
  onDone
}: React.PropsWithChildren<Props>) => {
  const ldClient = useLDClient();
  const [isInitialized, setIsInitialized] = React.useState(false);

  const onDoneRef = useLatest(onDone);
  const handleIdentifyDone = React.useCallback(() => {
    setIsInitialized(true);
    onDoneRef.current?.();
  }, [onDoneRef]);

  useDeepCompareEffect(() => {
    if (ldClient && context) {
      ldClient.identify(context, undefined, handleIdentifyDone);
    }
  }, [ldClient, context, handleIdentifyDone]);

  return isInitialized ? children : null;
};
