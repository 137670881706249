import merge from 'lodash/merge';

import BrandonTextBlack from '@irc/shared-assets/fonts/brandon/brandon-black.woff';
import BrandonTextBold from '@irc/shared-assets/fonts/brandon/brandon-bold.woff';
import BrandonTextLight from '@irc/shared-assets/fonts/brandon/brandon-light.woff';
import BrandonTextMedium from '@irc/shared-assets/fonts/brandon/brandon-medium.woff';
import BrandonTextRegular from '@irc/shared-assets/fonts/brandon/brandon-regular.woff';
import BrandonTextThin from '@irc/shared-assets/fonts/brandon/brandon-thin.woff';
import { Theme } from '@mui/material/styles';

export const createOverrides = ({
  palette,
  components
}: Theme): Theme['components'] =>
  merge(components, {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Brandon Text';
          font-style: normal;
          font-display: swap;
          font-weight: 100;
          src: local('Brandon Text Thin'), local('Brandon-Text-Thin'),
          url('${BrandonTextThin}') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
        }
        @font-face {
          font-family: 'Brandon Text';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Brandon Text Light'), local('Brandon-Text-Light'),
          url('${BrandonTextLight}') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
        }
        @font-face {
          font-family: 'Brandon Text';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Brandon Text Regular'), local('Brandon-Text-Regular'),
          url('${BrandonTextRegular}') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
        }
        @font-face {
          font-family: 'Brandon Text';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Brandon Text Medium'), local('Brandon-Text-Medium'),
          url('${BrandonTextMedium}') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
        }
        @font-face {
          font-family: 'Brandon Text';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Brandon Text Bold'), local('Brandon-Text-Bold'),
          url('${BrandonTextBold}') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
        }
        @font-face {
          font-family: 'Brandon Text';
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: local('Brandon Text Black'), local('Brandon-Text-Black'),
          url('${BrandonTextBlack}') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
        }
      `
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: `${palette.common.white} !important`,
          border: `1px solid ${palette.grey[300]}`,
          borderRadius: '4px',
          '&.Mui-focused': {
            borderColor: `${palette.primary.main} !important`
          },
          '&.Mui-error': {
            borderColor: `${palette.error.main} !important`
          }
        },
        underline: {
          '&::before, &::after': {
            borderBottom: '0px !important'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.common.white
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: `${palette.primary.main} !important`
          },
          '&.Mui-error': {
            color: `${palette.error.main} !important`
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: `${palette.primary.main} !important`
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: `${palette.grey[600]} !important`
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontWeight: 600
        },
        h5: {
          fontWeight: 500
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          boxShadow: 'none !important',
          '&:hover': {
            boxShadow: 'none !important'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent !important' // why is this important?
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          color: palette.text.primary,
          borderRadius: 4
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(18, 41, 70, 0.5)'
        }
      }
    }
  });
