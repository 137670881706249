import { useAppState } from '@irc/carli-components';
import { LaunchDarklyIdentifySessionUser } from '@irc/shared-services-launch-darkly';

export const LaunchDarklyIdentify = () => {
  const [appState] = useAppState();

  return (
    <LaunchDarklyIdentifySessionUser
      custom={{
        location: appState?.selectedLocationCode ?? '',
        sourceApplication: 'Carli'
      }}
    />
  );
};
