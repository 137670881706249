import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@irc/shared-components-progress-indicator';
import type { User } from '@irc/shared-services-auth';

import {
  SignInCallbackHandler as AuthCallback,
  SignInState
} from '../../components/SignInCallbackHandler';

type Props = {
  redirectUri?: string;
};

export const SignInCallback = ({ redirectUri = '/' }: Props) => {
  const navigate = useNavigate();

  const handleSignIn = useCallback(
    (user: User | null) => {
      console.debug('SigninCallback:', user);
      const state = user?.state as SignInState;

      setImmediate(() => {
        navigate(state?.redirectUri || redirectUri, { replace: true });
      });
    },
    [navigate, redirectUri]
  );

  return (
    <AuthCallback onSignIn={handleSignIn}>
      <Spinner isFullScreen />
    </AuthCallback>
  );
};
