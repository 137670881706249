import { useReducer } from 'react';

export type BulkUpdateResultsItem = [string, string];

export type BulkUpdateState = {
  succeeded: BulkUpdateResultsItem[];
  skipped: BulkUpdateResultsItem[];
  failed: BulkUpdateResultsItem[];
  isPending: boolean;
};

type BulkUpdateStateAction =
  | { type: 'succeeded' | 'skipped' | 'failed'; payload: BulkUpdateResultsItem }
  | { type: 'isPending'; payload: boolean }
  | { type: 'reset' };

export const bulkUpdateReducer = (
  state: BulkUpdateState,
  action: BulkUpdateStateAction
) => {
  switch (action.type) {
    case 'succeeded':
    case 'skipped':
    case 'failed':
      return {
        ...state,
        [action.type]: [...state[action.type], action.payload]
      };
    case 'isPending':
      return { ...state, [action.type]: action.payload };
    case 'reset':
    default:
      return INITIAL_BULK_UPDATE_STATE;
  }
};

export const INITIAL_BULK_UPDATE_STATE: BulkUpdateState = Object.freeze({
  succeeded: [],
  skipped: [],
  failed: [],
  isPending: false
});

export const useBulkUpdateReducer = () =>
  useReducer(bulkUpdateReducer, INITIAL_BULK_UPDATE_STATE);
