import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @deprecated use useQueryParams instead
 */
export const useSearchParams = (): URLSearchParams => {
  const { search } = useLocation();

  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
};
