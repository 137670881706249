import { useCallback, useEffect, useReducer } from 'react';

import {
  createPlayer,
  getPlayerUrl,
  InlineManualTracking,
  loadAsync
} from '../utils';

export type State = {
  isLoading: boolean;
  error: Error | null;
};

export const reducer = (state: State, action: Partial<State>) => ({
  ...state,
  ...action
});

export const INITIAL_STATE: State = Object.freeze({
  isLoading: false,
  error: null
});

export const useInlineManual = (apiKey: string) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const playerUrl = apiKey ? getPlayerUrl(apiKey) : undefined;

  useEffect(() => {
    if (playerUrl) {
      dispatch({ isLoading: true });
      loadAsync(playerUrl)
        .catch((error) => {
          dispatch({ error });
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  }, [playerUrl]);

  const create = useCallback(
    (user?: InlineManualTracking) => {
      try {
        createPlayer(user);
      } catch (error) {
        dispatch({ error: error as Error });
      }
    },
    [dispatch]
  );

  return [state, create] as const;
};
