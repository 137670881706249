import { PropsWithChildren, useEffect } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps
} from 'react-error-boundary';

import { env } from '@irc/shared-utils-environment';

import { ErrorInfo } from '../models/ErrorInfo';

import { HostReportErrors } from './HostReportErrors';
import { ErrorRedirect } from './Redirect';

export const ErrorBoundary = ({ children }: PropsWithChildren<unknown>) => {
  const onError = (error: Error) => {
    HostReportErrors.rethrow(error);
  };

  let content = <>{children}</>;

  if (env['NODE_ENV'] !== 'development') {
    content = (
      <ReactErrorBoundary onError={onError} FallbackComponent={ErrorHandler}>
        {children}
      </ReactErrorBoundary>
    );
  }

  return content;
};

const ErrorHandler = (props: FallbackProps) => {
  const { error, resetErrorBoundary } = props;

  useEffect(() => {
    resetErrorBoundary();
  }, [error, resetErrorBoundary]);

  return <ErrorRedirect error={error} info={ErrorInfo.Unhandled} />;
};
