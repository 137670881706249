import { PropsWithChildren, useEffect } from 'react';

import { useAuthClient } from '@irc/shared-services-auth';

export const SilentCallbackHandler = ({ children }: PropsWithChildren) => {
  const client = useAuthClient();

  useEffect(() => {
    console.log('SilentCallbackHandler=', 'useEffect');
    client
      .signInCallback()
      .catch((e) => console.error('silent renew failed: ', e));
  }, [client]);

  return <>{children}</>;
};
