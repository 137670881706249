import { User } from './models';

export type AuthStatus =
  | 'undefined'
  | 'unauthenticated'
  | 'authenticating'
  | 'authenticated';

export interface AuthState {
  status: AuthStatus;
  isHydrated: boolean;
  isExpiring: boolean;
  isLoading: boolean;
  user: User | null;
  error: Error | null;
}

export type AuthAction =
  | { type: 'loadingUser' }
  | { type: 'loadingUserError'; payload: Error }
  | { type: 'userLoaded'; payload: User }
  | { type: 'userExpiring' }
  | { type: 'userExpired' }
  | { type: 'silentRenewError'; payload: Error }
  | { type: 'userSignedOut' }
  | { type: 'userTerminated' }
  | { type: 'userHydrated' };

export const DEFAULT_AUTH_STATE: AuthState = {
  status: 'undefined',
  isHydrated: false,
  isLoading: false,
  isExpiring: false,
  user: null,
  error: null
};

export const reducer:
  | React.Reducer<AuthState, AuthAction>
  | React.ReducerWithoutAction<AuthState> = (state, action) => {
  console.debug('Auth State Change:', state, action);
  switch (action.type) {
    case 'loadingUser':
      return {
        ...state,
        status: 'authenticating',
        isLoading: true,
        error: null
      };
    case 'loadingUserError':
      return {
        ...state,
        status: 'unauthenticated',
        isLoading: false,
        error: action.payload
      };
    case 'userLoaded':
      return {
        ...state,
        status: 'authenticated',
        user: action.payload,
        isLoading: false,
        isExpiring: false
      };
    case 'userExpiring':
      return { ...state, isExpiring: true };
    case 'userExpired':
      return {
        ...state,
        status: 'unauthenticated',
        user: null,
        isExpiring: false,
        isLoading: false
      };
    case 'silentRenewError':
      return { ...state, error: action.payload };
    case 'userSignedOut':
    case 'userTerminated':
      return {
        ...DEFAULT_AUTH_STATE,
        status: 'unauthenticated',
        user: null,
        isLoading: false
      };
    case 'userHydrated':
      return { ...state, isHydrated: true };
    default:
      return state ?? DEFAULT_AUTH_STATE;
  }
};
