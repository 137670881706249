import { TYPOGRAPHY } from '@irc/shared-theme';
import { styled } from '@mui/material/styles';

type Props = {
  className?: string;
  variant: keyof typeof TYPOGRAPHY;
  text: React.ReactNode;
};

type StyleProps = Pick<Props, 'variant'>;
const Root = styled('div')<StyleProps>`
  ${({ variant }) => TYPOGRAPHY[variant]}
`;

export const Typography = ({ className, variant, text }: Props) => (
  <Root className={className} variant={variant}>
    {text}
  </Root>
);
