export const reduceStyle = (action?: string) => {
  switch (action) {
    case 'top':
    case 'left':
      return 'flex-start';

    case 'bottom':
    case 'right':
      return 'flex-end';

    default:
      return 'center';
  }
};
