import { TYPOGRAPHY } from '@irc/shared-theme';
import { styled } from '@mui/material/styles';

export const Header = styled('h1')`
  ${TYPOGRAPHY.headerXL}
  color: ${({ theme }) => theme.palette.text.primary};
  margin-top: 0;
  margin-bottom: 3.25rem;
`;

export const Summary = styled('p')`
  ${TYPOGRAPHY.bodyM}
  color: ${({ theme }) => theme.palette.text.primary};
  margin: 0;
  text-align: center;
`;

export const Highlight = styled('em')`
  font-style: normal;
  color: ${({ theme }) => theme.palette.error.main}};
`;
