import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import MuiAvatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Root = styled(ListItemButton)`
  background-color: #fff;
  display: flex;
  min-width: 300px;
  max-width: 500px;
  padding: 16px 32px;

  & a:hover {
    text-decoration: none;
  }
`;

const AvatarWrapper = styled(ListItemAvatar)`
  display: flex;
  flex: 1;
`;

const Avatar = styled(MuiAvatar, {
  shouldForwardProp: (prop) => !prop.toString().startsWith('$')
})<{ $iconOnly?: boolean }>`
  flex-shrink: unset;
  height: 60px;
  width: ${({ $iconOnly }) => ($iconOnly ? 'auto' : '60px')};

  & img {
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: contain;
    text-align: center;
    text-indent: 10000;
  }
`;

const Content = styled(ListItemText, {
  shouldForwardProp: (prop) => !prop.toString().startsWith('$')
})<{ $iconOnly?: boolean }>`
  ${({ $iconOnly }) => ($iconOnly ? 'flex: none' : 'display: block')};

  & .MuiListItemText-primary {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: right;
    white-space: pre-line;
  }

  & .MuiListItemText-secondary {
    text-align: right;
  }
`;

export interface Props {
  className?: string;
  icon?: string;
  label?: ReactNode;
  title?: string;
  description?: ReactNode;
  disabled?: boolean;
  to?: string;
}

export const Tile = ({
  className,
  icon,
  label,
  description,
  disabled,
  to,
  title
}: Props) => {
  const navigate = useNavigate();
  const isIconOnly = Boolean(icon && !label && !description);

  const handleClick = () => {
    if (!to) return;

    if (/^https?:\/\//.test(to)) {
      window.open(to);
    } else {
      navigate(to);
    }
  };

  return (
    <Root
      title={title}
      className={className}
      {...{ component: Paper }}
      onClick={handleClick}
      disabled={disabled}
    >
      {icon ? (
        <AvatarWrapper>
          <Avatar $iconOnly={isIconOnly} variant='square' src={icon} />
        </AvatarWrapper>
      ) : null}
      <Content $iconOnly={isIconOnly} primary={label} secondary={description} />
    </Root>
  );
};
