import { forwardRef, MouseEvent, PropsWithChildren } from 'react';

import MUIMenuItem from '@mui/material/MenuItem';

type Props = {
  className?: string;
  disabled?: boolean;
  selected?: boolean;
  value?: string | number;
  onClick?: (evt: MouseEvent<HTMLLIElement>) => void;
};

export const MenuItem = forwardRef<HTMLLIElement, PropsWithChildren<Props>>(
  (props, ref) => {
    const { children, ...rest } = props;

    return (
      <MUIMenuItem ref={ref} {...rest}>
        {children}
      </MUIMenuItem>
    );
  }
);
