import { Spinner as ShowroomSpinner } from '@carvana/showroom-spinner';
import { Box } from '@mui/material';

export const Spinner = (
  props: React.ComponentProps<typeof ShowroomSpinner>
) => {
  return (
    <Box role='progressbar'>
      <ShowroomSpinner {...props} />
    </Box>
  );
};
