import React from 'react';
import debounce from 'lodash/debounce';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';

import useStyles from './styles';
import { SearchFieldResultType } from './types';
import {
  isStockNumber,
  isValidStockOrVinLength,
  isVin,
  sanitizeInput
} from './utils';

export interface SearchFieldResult {
  type: SearchFieldResultType;
  value: string;
}

export interface SearchFieldState {
  invalid?: boolean;
  result?: SearchFieldResult;
}

export type SearchFieldAction =
  | { type: 'set'; payload: Partial<SearchFieldState> }
  | { type: 'reset' };

export type Props = TextFieldProps & {
  timeout?: number;
  onSearch?: (result: SearchFieldResult) => void;
  onCancel?: () => void;
  inputMask?: RegExp;
  inputMode?: 'none' | 'text';
  showKeyboardIcon?: boolean;
  onKeyboardIconClick?: () => void;
};

export const INITIAL_STATE = Object.freeze({
  invalid: false
});

export const reducer:
  | React.Reducer<SearchFieldState, SearchFieldAction>
  | React.ReducerWithoutAction<SearchFieldState> = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'reset':
      return INITIAL_STATE;
  }
};

export const SearchField = ({
  timeout = 750,
  onSearch,
  onCancel,
  onKeyUp,
  onChange,
  onSubmit,
  error,
  inputMask = /[^a-z0-9]/gi,
  helperText,
  value: propsValue,
  InputProps,
  inputRef: ref,
  inputMode = 'text',
  showKeyboardIcon = false,
  onKeyboardIconClick,
  ...inputProps
}: Props) => {
  const styles = useStyles();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  const handleSearchTerm = React.useCallback(
    (searchTerm: string) => {
      const nextValue = sanitizeInput(searchTerm);

      if (isValidStockOrVinLength(nextValue)) {
        if (isStockNumber(nextValue)) {
          const result: SearchFieldResult = {
            value: nextValue,
            type: SearchFieldResultType.StockNumber
          };
          dispatch({ type: 'set', payload: { result } });
        } else if (isVin(nextValue)) {
          const result: SearchFieldResult = {
            value: nextValue,
            type: SearchFieldResultType.Vin
          };
          dispatch({ type: 'set', payload: { result } });
        } else {
          dispatch({ type: 'set', payload: { invalid: true } });
        }
      }
    },
    [dispatch]
  );

  const debouncedOnScan = React.useMemo(() => {
    return debounce(handleSearchTerm, timeout);
  }, [handleSearchTerm, timeout]);

  React.useEffect(() => {
    if (state.result && onSearch) {
      onSearch(state.result);
      dispatch({ type: 'set', payload: { result: undefined } });
    }
  }, [state.result, onSearch]);

  React.useEffect(
    () => () => {
      debouncedOnScan.cancel();
    },
    [debouncedOnScan]
  );

  const handleOnSubmit = (evt: React.FormEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.blur();
    }

    if (onSubmit) {
      onSubmit(evt);
    }
  };

  const handleOnKeyUp = (
    evt: React.KeyboardEvent<HTMLInputElement> & { code: string }
  ) => {
    if (evt.key === 'Enter' || evt.code === 'Enter') {
      if (inputRef.current) inputRef.current.blur();
      debouncedOnScan.flush();
    } else if (evt.key === 'Escape') {
      handleCloseClick();
    }

    if (onKeyUp) {
      onKeyUp(evt);
    }
  };

  const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    let nextValue = value;
    if (inputMask) nextValue = value.replace(inputMask, '');

    if (inputRef.current) {
      inputRef.current.value = nextValue;
    }

    debouncedOnScan(nextValue);
    dispatch({ type: 'set', payload: { invalid: false } });

    if (onChange) {
      onChange(evt);
    }

    if (!value && onCancel) {
      onCancel();
    }
  };

  const handleCloseClick = () => {
    dispatch({ type: 'reset' });

    const input = inputRef.current;

    if (input) {
      input.value = '';
      input.focus();
    }

    if (onCancel) {
      onCancel();
    }
  };

  const renderStartAdornment = () => (
    <IconButton disableRipple disableFocusRipple className={styles.adornment}>
      <SearchIcon titleAccess='search' />
    </IconButton>
  );

  const renderEndAdornment = () =>
    inputRef.current?.value || propsValue ? (
      <IconButton className={styles.adornment} onClick={handleCloseClick}>
        <CloseIcon titleAccess='cancel' />
      </IconButton>
    ) : null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <TextField
        inputRef={inputRef}
        value={propsValue}
        onKeyUp={handleOnKeyUp}
        onChange={handleOnChange}
        onSubmit={handleOnSubmit}
        InputProps={{
          startAdornment: renderStartAdornment(),
          endAdornment: renderEndAdornment(),
          className: styles.input,
          ...InputProps
        }}
        inputProps={{ inputMode }}
        error={state.invalid || error}
        helperText={state.invalid ? 'Please try again.' : helperText}
        {...inputProps}
      />
      {showKeyboardIcon && (
        <IconButton
          sx={{
            padding: 0,
            paddingLeft: '6px'
          }}
          color='primary'
          onClick={onKeyboardIconClick}
        >
          <KeyboardIcon fontSize='medium' />
        </IconButton>
      )}
    </Box>
  );
};
