import { PropsWithChildren } from 'react';

import { useStorageItem } from '@irc/shared-utils-storage';

import {
  AppLayout,
  AppStateContext,
  AppStorageSchema
} from '../hooks/useAppState';

export const INITIAL_APP_STATE = Object.freeze({
  layout: AppLayout.Unset
});

export const AppStateProvider = ({ children }: PropsWithChildren) => {
  const appState = useStorageItem<AppStorageSchema>('carli', INITIAL_APP_STATE);

  return (
    <AppStateContext.Provider value={appState}>
      {children}
    </AppStateContext.Provider>
  );
};
