import { User as OidcUser } from 'oidc-client-ts';

export class User extends OidcUser {
  get initials() {
    const name = this.profile.name || '';
    const parts = name.split(' ');

    return parts.reduce((initials, part) => {
      return (initials += part.slice(0, 1));
    }, '');
  }

  get isAuthenticated() {
    return this.access_token != null && this.expired === false;
  }
}
