import { useNavigate } from 'react-router-dom';

import { isAbsoluteUrl } from '@irc/shared-services-auth';

import {
  SignOutCallbackHandler as AuthCallback,
  SignOutState
} from '../../components/SignOutCallbackHandler';

type Props = {
  redirectUri?: string;
};

export const SignOutCallback = ({ redirectUri = '/' }: Props) => {
  const navigate = useNavigate();
  const handleSignOut = (state?: SignOutState) => {
    console.debug('SignoutCallback:', state);
    const uriToNavigate = state?.redirectUri || redirectUri;
    setImmediate(() => {
      if (isAbsoluteUrl(uriToNavigate)) {
        window.location.href = uriToNavigate;
      } else {
        navigate(uriToNavigate, { replace: true });
      }
    });
  };
  return <AuthCallback onSignOut={handleSignOut} />;
};
