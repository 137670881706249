import { useEffect } from 'react';

import { useAuthState } from '@irc/shared-services-auth';

import { useInlineManual } from './hooks/useInlineManual';
import { InlineManualTracking } from './utils';

type Props = {
  apiKey: string;
  trackUser?: boolean;
};

export const InlineManual = ({ apiKey, trackUser }: Props) => {
  const { user } = useAuthState();
  const [{ isLoading }, createPlayer] = useInlineManual(apiKey);

  useEffect(() => {
    if (user && !isLoading) {
      const tracking = trackUser
        ? ({
            uid: user.profile.email as string,
            email: user.profile.email,
            name: user.profile.name,
            roles: [user.profile.user_type, user.profile.title]
          } as InlineManualTracking)
        : undefined;

      createPlayer(tracking);
    }
  }, [user, isLoading, createPlayer, trackUser]);

  return null;
};
