import { TYPOGRAPHY } from '@irc/shared-theme';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const Root = styled('div')`
  background-color: ${({ theme }) => theme.palette.info.dark};
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(4)}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100%;
`;

export const Title = styled(Typography)`
  ${TYPOGRAPHY.headerXXL}
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const Subtitle = styled(Typography)`
  ${TYPOGRAPHY.bodyL}
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const SignInButton = styled(Button)`
  ${TYPOGRAPHY.labelS}
  max-width: ${({ theme }) => theme.breakpoints.values.sm};
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.info.light};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

// export const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
//   root: {
//     backgroundColor: palette.info.dark,
//     padding: `${spacing(3)} ${spacing(4)}`,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     textAlign: 'center',
//     minHeight: '100%'
//   },
//   title: {
//     ${TYPOGRAPHY.headerXXL},
//     color: palette.common.white,
//     marginTop: spacing(1)
//   },
//   subtitle: {
//     ${TYPOGRAPHY.bodyL},
//     color: palette.common.white,
//     marginTop: spacing(2),
//     marginBottom: spacing(3)
//   },
//   button: {
//     ${TYPOGRAPHY.labelS},
//     maxWidth: breakpoints.values.sm,
//     backgroundColor: palette.common.white,
//     color: palette.info.light,
//     paddingTop: spacing(2),
//     paddingBottom: spacing(2)
//   },
//   '@global': {
//     'html, body, #root': {
//       height: '100%',
//       backgroundColor: palette.info.dark
//     }
//   }
// }));
