import { Dispatch, useEffect } from 'react';

import { Client, User } from '../models';
import { AuthAction } from '../state';
import { EventHandlers } from '../types';

const bind = (client: Client, bindings: EventHandlers<User>) => {
  client.on('userLoaded', bindings.onUserLoaded);
  client.on('silentRenewError', bindings.onSilentRenewError);
  client.on('accessTokenExpired', bindings.onAccessTokenExpired);
  client.on('accessTokenExpiring', bindings.onAccessTokenExpiring);
  client.on('userUnloaded', bindings.onUserUnloaded);
  client.on('userSignedOut', bindings.onUserSignedOut);

  return () => {
    client.removeAllListeners();
  };
};

/* eslint-disable consistent-return */
export const useEventBindings = (
  client: Client,
  dispatch: Dispatch<AuthAction>
) => {
  const isIframe = window.frameElement || window.self !== window.top;

  useEffect(() => {
    const onUserSignedOut = async () => {
      try {
        const user = await client.getUser();

        /*
          We check for the user here because a real sign-out request would remove the users, but other things
          like the session monitor can explode due to 3rd party cookie blocking polices, and will interpret
          any network error (3rd party cookie blocking policy) as a unauthorized request and raise the event
          while the current session is actually still valid and not signed out.
        */
        if (user == null) {
          dispatch({ type: 'userSignedOut' });
        }
      } catch {
        dispatch({ type: 'userSignedOut' });
      }
    };

    const bindings: EventHandlers<User> = {
      onUserLoaded: (user: User) =>
        dispatch({ type: 'userLoaded', payload: user }),
      onSilentRenewError: (err: Error) =>
        dispatch({ type: 'silentRenewError', payload: err }),
      onAccessTokenExpired: () => dispatch({ type: 'userExpired' }),
      onAccessTokenExpiring: () => dispatch({ type: 'userExpiring' }),
      onUserUnloaded: () => dispatch({ type: 'userTerminated' }),
      onUserSignedOut
    };

    return !isIframe ? bind(client, bindings) : () => void 0;
  }, [client, dispatch, isIframe]);
};
