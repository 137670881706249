import { useEffect } from 'react';

import {
  toRedirectUriState,
  toRelativeUrl,
  useAuthClient
} from '@irc/shared-services-auth';

export const Challenge = () => {
  const client = useAuthClient();

  const redirectUri = toRelativeUrl(
    window.location.href,
    window.location.origin
  );

  useEffect(() => {
    client.signIn(toRedirectUriState(redirectUri));
  }, [client, redirectUri]);

  return null;
};
