import { ThemeProvider as CarvanaThemeProvider } from '@carvana/showroom-theme';
import { CssBaseline } from '@mui/material';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles';
import { ThemeProviderProps } from '@mui/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const ThemeProvider = ({ children, ...props }: ThemeProviderProps) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider {...props}>
      <CssBaseline />
      <CarvanaThemeProvider>{children}</CarvanaThemeProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
);
