import Axios, { AxiosRequestConfig } from 'axios';

import { env } from '@irc/shared-utils-environment';
import {
  getPagingHeader,
  ServiceClientResponse,
  ServiceErrorType
} from '@irc/shared-utils-http';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: `${env.DOMAIN_APIK}/scm/vtram/`
});

export const client = <TData>(
  innerConfig?: AxiosRequestConfig,
  outerConfig?: AxiosRequestConfig
): Promise<ServiceClientResponse<TData>> => {
  // patch interceptors
  AXIOS_INSTANCE.interceptors.request = Axios.interceptors.request;
  AXIOS_INSTANCE.interceptors.response = Axios.interceptors.response;

  return AXIOS_INSTANCE({
    ...innerConfig,
    ...outerConfig
  }).then(({ data, headers }) => ({
    responseBody: data,
    pagingHeader: getPagingHeader(headers)
  }));
};

export type { ServiceErrorType as ErrorType };
