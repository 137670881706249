import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { env } from '@irc/shared-utils-environment';

import { TrackUser } from './TrackUser';

type Props = {
  applicationId: string;
  clientToken: string;
  service: string;
  enableSessionReplayRecording?: boolean;
  version?: string;
  tracingOrigins?: (string | RegExp)[];
  trackUser?: boolean;
  customSessionId?: string;
};

export const Datadog = ({
  applicationId,
  clientToken,
  service,
  enableSessionReplayRecording,
  version,
  tracingOrigins,
  trackUser,
  customSessionId
}: Props) => {
  useEffect(() => {
    if (customSessionId) {
      datadogRum.setRumGlobalContext({ customSessionId });
    }
    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service,
      env: env.NX_PUBLIC_ENVIRONMENT,
      version,
      sessionSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls:
        tracingOrigins ??
        (env.NX_PUBLIC_DOMAIN
          ? [env.NX_PUBLIC_DOMAIN, env.NX_PUBLIC_DOMAIN_APIK ?? '']
          : []),
      traceSampleRate: 20
    });
  }, [
    applicationId,
    clientToken,
    service,
    version,
    tracingOrigins,
    customSessionId
  ]);

  useEffect(() => {
    if (enableSessionReplayRecording) {
      datadogRum.startSessionReplayRecording();
    }

    return () => {
      datadogRum.stopSessionReplayRecording();
    };
  }, [enableSessionReplayRecording]);

  return trackUser ? <TrackUser /> : null;
};
