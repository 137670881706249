import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useAuthState } from '@irc/shared-services-auth';

export const TrackUser = () => {
  const { user } = useAuthState();
  const { profile } = user || {};

  useEffect(() => {
    if (profile) {
      datadogRum.setUser({
        id: profile.email,
        name: profile.name,
        email: profile.email,
        title: profile.title
      });
    }

    return () => {
      datadogRum.removeUser();
    };
  }, [profile]);

  return null;
};
