export type DefaultSchema = Record<string, unknown>;

export interface Readable<Schema> {
  keys?: () => Promise<string[]>;
  getItem: <K extends keyof Schema>(key: K) => Promise<Schema[K] | null>;
  getItems: <K extends keyof Schema>(
    comparer?: (value: Schema[K]) => boolean
  ) => Promise<Schema[K][]>;
}

export interface Writeable<Schema> {
  setItem: <K extends keyof Schema>(
    key: K,
    data: Partial<Schema[K]>
  ) => Promise<Schema[K] | undefined>;
  removeItem: <K extends keyof Schema>(key: K) => Promise<void>;
  clear?: () => Promise<void>;
}

export type QueryableState = 'Idle' | 'Pending' | 'Complete' | 'Error';
export interface Queryable {
  queryState?: QueryableState;
}

export interface Serializable<Schema> {
  serialize: () => string;
  deserialize: () => Schema;
}

export const MethodNotImplemented = new Error('MethodNotImplemented');
