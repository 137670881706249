import { ReactNode } from 'react';
import $C from 'classnames';

import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MuiToolbar from '@mui/material/Toolbar';

import useStyles from './styles';

type Props = Pick<
  AppBarProps,
  'variant' | 'position' | 'className' | 'style'
> & {
  dense?: boolean;
  disableGutters?: boolean;
  leftComponent?: ReactNode;
  centerComponent?: ReactNode;
  rightComponent?: ReactNode;
  leftComponentClassName?: string;
  centerComponentClassName?: string;
  rightComponentClassName?: string;
};

function renderComponent<P = unknown>(
  component: ReactNode | ((props?: P) => ReactNode),
  props?: P
) {
  return typeof component === 'function' ? component(props) : component;
}

export const Toolbar = (props: Props) => {
  const {
    dense,
    disableGutters,
    style,
    className,
    variant = 'outlined',
    position = 'static',
    leftComponent,
    centerComponent,
    rightComponent,
    leftComponentClassName,
    centerComponentClassName,
    rightComponentClassName
  } = props;
  const styles = useStyles();

  return (
    <AppBar
      style={style}
      className={$C(styles.root, className)}
      variant={variant}
      position={position}
      elevation={0}
    >
      <MuiToolbar
        disableGutters={disableGutters}
        variant={dense ? 'dense' : 'regular'}
      >
        <Box
          className={$C(
            'toolbar-left',
            styles.leftComponent,
            leftComponentClassName
          )}
        >
          {renderComponent(leftComponent)}
        </Box>
        <Box
          className={$C(
            'toolbar-center',
            styles.centerComponent,
            centerComponentClassName
          )}
        >
          {renderComponent(centerComponent)}
        </Box>
        <Box
          className={$C(
            'toolbar-right',
            styles.rightComponent,
            rightComponentClassName
          )}
        >
          {renderComponent(rightComponent)}
        </Box>
      </MuiToolbar>
    </AppBar>
  );
};
