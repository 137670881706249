import { ReactComponent as CarvanaLogo } from '@irc/shared-assets/illustrations/carvana-white.svg';
import { useAuthClient } from '@irc/shared-services-auth';
import Container from '@mui/material/Container';

import { SecuredRedirect } from '../../components';

import { Root, SignInButton, Subtitle, Title } from './styles';

type Props = {
  applicationName: string;
  redirectUri?: string;
};

export const SignIn = ({ applicationName, redirectUri = '/' }: Props) => {
  const client = useAuthClient();

  const handleSignIn = () => {
    client.signIn();
  };

  return (
    <SecuredRedirect to={redirectUri}>
      <Root>
        <Container maxWidth='md'>
          <CarvanaLogo />
          <Title variant='h1'>Welcome to {applicationName}</Title>
          <Subtitle>Sign in to get started</Subtitle>
          <SignInButton
            variant='contained'
            fullWidth
            size='large'
            onClick={handleSignIn}
          >
            Sign in
          </SignInButton>
        </Container>
      </Root>
    </SecuredRedirect>
  );
};
