import pLimit from 'p-limit';

import {
  BulkUpdateResultsItem,
  useBulkUpdateReducer
} from '../hooks/useBulkUpdateReducer';

export class SkipError extends Error {
  override cause?: unknown = 'skipped';
}

export type UseBulkProcessorResult<T> = {
  processUpdates: (paramsList: [string, T][]) => Promise<void>;
  isPending: boolean;
  succeeded: BulkUpdateResultsItem[];
  skipped: BulkUpdateResultsItem[];
  failed: BulkUpdateResultsItem[];
};

export const createBulkProcessorHook = (maxConcurrent = 1) => {
  const rateLimiter = pLimit(maxConcurrent);

  return function useBulkProcessor<T>(
    fn: (params: T) => Promise<unknown>
  ): UseBulkProcessorResult<T> {
    const [{ isPending, succeeded, skipped, failed }, setState] =
      useBulkUpdateReducer();

    const processUpdates = async (paramsList: [string, T][]) => {
      try {
        setState({ type: 'reset' });
        setState({ type: 'isPending', payload: true });

        const tasks = paramsList.map<ReturnType<typeof rateLimiter>>(
          ([id, params]) =>
            rateLimiter(async () => {
              try {
                // TODO: can we leverage the response from fn for anything??
                await fn(params);
                setState({ type: 'succeeded', payload: [id, ''] });
              } catch (err) {
                const message =
                  err instanceof Error ? err.message : (err as string);
                if (err instanceof SkipError) {
                  setState({
                    type: 'skipped',
                    payload: [
                      id,
                      `update skipped - New selection matches current value (${
                        typeof params === 'string'
                          ? params
                          : JSON.stringify(params)
                      })`
                    ]
                  });
                } else {
                  setState({
                    type: 'failed',
                    payload: [id, message]
                  });
                }
              } finally {
                console.debug({
                  active: rateLimiter.activeCount,
                  pending: rateLimiter.pendingCount
                });
              }
            })
        );
        await Promise.allSettled(tasks);
      } catch (e) {
        console.error('createBulkProcessorHook:', e);
      } finally {
        setState({ type: 'isPending', payload: false });
      }
    };

    return { processUpdates, isPending, succeeded, skipped, failed };
  };
};
