import { Link as RouterLink } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import carliLogo from '@irc/shared-assets/logos/carli-510px.png';
import carliIcon from '@irc/shared-assets/logos/carli-icon.png';
import { Toolbar } from '@irc/shared-components-toolbar';
import { useAuthState } from '@irc/shared-services-auth';
import { styled } from '@mui/material/styles';

import { Menu } from './components/Menu';

const Root = styled(Toolbar)`
  background-color: ${({ theme }) => theme.palette.text.primary} !important;
  border: 0 none;
  min-height: 88px;
  justify-content: center;
`;

const LargeImage = styled('img')`
  max-width: 190px;
`;

const SmallImage = styled('img')`
  max-width: 50px;
`;

export const Header = () => {
  const { status } = useAuthState();
  const { width } = useWindowSize();

  const renderLeftComponent = (
    <RouterLink to='/home'>
      {width > 450 ? (
        <LargeImage src={carliLogo} className='logo-large' alt='Carli' />
      ) : (
        <SmallImage src={carliIcon} className='logo-small' alt='Carli' />
      )}
    </RouterLink>
  );

  const renderRightComponent = status === 'authenticated' ? <Menu /> : null;

  return (
    <Root
      leftComponent={renderLeftComponent}
      rightComponent={renderRightComponent}
    />
  );
};
