import { PropsWithChildren } from 'react';

import { Header } from '@irc/carli-features-header';
import { CustomLink, Footer } from '@irc/shared-components-footer';
import { styled } from '@mui/material/styles';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Body = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  position: relative;
  overflow: auto;
`;

interface PageLayoutProps extends PropsWithChildren {
  footerLinks?: CustomLink[];
}

export const PageLayout = ({ children, footerLinks = [] }: PageLayoutProps) => {
  return (
    <Root>
      <Header />
      <Body>{children}</Body>
      <Footer customLinks={footerLinks} />
    </Root>
  );
};
