import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

export type PagingHeader = {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPage: number;
};

export const DEFAULT_PAGING_HEADER: PagingHeader = Object.freeze({
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
  totalPage: 0
});

export function getPagingHeader(
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders = {}
): PagingHeader | undefined {
  try {
    if (!('paging-header' in headers)) return undefined;

    const rawHeader = JSON.parse(headers?.['paging-header']);

    return {
      pageNumber: rawHeader.PageNumber || DEFAULT_PAGING_HEADER.pageNumber,
      pageSize: rawHeader.PageSize || DEFAULT_PAGING_HEADER.pageNumber,
      totalCount: rawHeader.TotalCount || DEFAULT_PAGING_HEADER.totalCount,
      totalPage: rawHeader.TotalPage || DEFAULT_PAGING_HEADER.totalPage
    };
  } catch (err) {
    return undefined;
  }
}
