import { isNil, isPlainObject } from 'lodash';
import { compile, parse, PathFunction, Token } from 'path-to-regexp';
import queryString from 'query-string';
import { createBrowserRouter, Params, RouteObject } from 'react-router-dom';

import { DecodedValueMap, EncodedValueMap, QueryParamMap } from './types';

export function deepOmit(obj: object) {
  return Object.entries(obj).reduce((accum, [key, val]) => {
    if (isPlainObject(val)) {
      const child = deepOmit(val);
      if (Object.keys(child).length) {
        accum[key] = child;
      }
    } else if (!isNil(val)) {
      accum[key] = val;
    }
    return accum;
  }, {} as any);
}

export function mapEncodedToSearchParams<T extends QueryParamMap>(
  obj: Partial<EncodedValueMap<T>>
) {
  return queryString.stringify(deepOmit(obj));
}

export function mapSearchParamsToEncoded<T extends QueryParamMap>(
  searchParams: URLSearchParams
) {
  return queryString.parse(searchParams.toString()) as Partial<
    EncodedValueMap<T>
  >;
}

export function encodeQueryParams<T extends QueryParamMap>(
  queryParamsMap: T,
  queryParams: Partial<DecodedValueMap<T>>
): Partial<EncodedValueMap<T>> {
  return Object.entries(queryParams).reduce((accum, [param, val]) => {
    const transfomer = queryParamsMap[param];
    const nextVal = transfomer ? transfomer.encode(val) : val;
    accum[param as keyof T] = nextVal;
    return accum;
  }, {} as EncodedValueMap<T>);
}

export function decodeQueryParams<T extends QueryParamMap>(
  queryParamsMap: T,
  queryParams: Partial<EncodedValueMap<T>>
): Partial<DecodedValueMap<T>> {
  return Object.entries(queryParamsMap).reduce(
    (decodedValues, [paramName, transformer]) => {
      const encodedValue = queryParams[paramName];
      decodedValues[paramName as keyof T] = transformer.decode(encodedValue);
      return decodedValues;
    },
    {} as DecodedValueMap<T>
  );
}

export function createQueryString(params?: Params) {
  if (!params) return;

  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return `?${queryString}`;
}

export type RouteMetadata<T extends Params = never> = {
  pattern: string;
  tokens: Token[];
  toPath: PathFunction<Partial<T>>;
};

export function createRouteMetadata<T extends Params>(
  pattern: string
): RouteMetadata<T> {
  const tokens = parse(pattern);
  const toPath = compile<Partial<T>>(pattern, { encode: encodeURIComponent });

  return {
    pattern,
    tokens,
    toPath
  };
}

export function createAppRouter(routes: RouteObject[]) {
  return createBrowserRouter(routes);
}
