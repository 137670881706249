import { useWindowSize } from 'react-use';

import { useTheme } from '@mui/material';

import { AppLayout, useAppState } from './useAppState';

export interface UseAppLayoutState {
  appLayout: AppLayout;
  isMobile: boolean;
}

export const useAppLayout = (): UseAppLayoutState => {
  const { breakpoints } = useTheme();
  const { width } = useWindowSize();
  const [appState] = useAppState();
  const pref = appState?.layout;

  const isMobile =
    pref === AppLayout.Unset
      ? width <= breakpoints.values.md // 768
      : pref === AppLayout.Mobile;

  const appLayout = isMobile ? AppLayout.Mobile : AppLayout.Desktop;

  return { appLayout, isMobile };
};
