import LocalForage from 'localforage';

import { InitSchema, Storage } from '../models/Storage';
import { DefaultSchema } from '../types';

export class LocalStorage<Schema = DefaultSchema> extends Storage<Schema> {
  constructor(init?: InitSchema) {
    super();

    const configuration = Storage._sanitize(init);
    this._store = LocalForage.createInstance({
      ...configuration,
      driver: [LocalForage.LOCALSTORAGE]
    });
  }
}
