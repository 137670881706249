import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    'z-index': '1100 !important',
    position: 'relative'
  },
  leftComponent: {
    flex: 1,
    alignItems: 'flex-start'
  },
  centerComponent: {
    flex: 2,
    overflow: 'hidden'
  },
  rightComponent: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex'
  }
}));
