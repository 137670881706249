import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { env } from '@irc/shared-utils-environment';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: `${env.DOMAIN_APIK}/scm/vehicleposition`
});

export const client = <TData>(
  innerConfig?: AxiosRequestConfig,
  outerConfig?: AxiosRequestConfig
): Promise<TData> => {
  // patch interceptors
  AXIOS_INSTANCE.interceptors.request = Axios.interceptors.request;
  AXIOS_INSTANCE.interceptors.response = Axios.interceptors.response;

  return AXIOS_INSTANCE({
    ...innerConfig,
    ...outerConfig
  }).then(({ data }) => data);
};

export type ErrorType<Error> = AxiosError<Error>;
