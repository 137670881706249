import { createContext } from 'react';

import { useContextWithError } from '@irc/shared-utils-react';

import { AuthState } from '../state';

export const AuthStateContext = createContext<AuthState | undefined>(undefined);

export const AUTH_STATE_ERROR =
  "Couldn't locate auth state. Is your component inside the AuthClientProvider?";

export const useAuthState = () =>
  useContextWithError(AuthStateContext, AUTH_STATE_ERROR);
