import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSearchParams } from '@irc/shared-utils-routing';

import { ErrorInfo } from '../models/ErrorInfo';
import { orUndefined } from '../utils/values';

export interface ErrorParams {
  code: string;
  name?: string;
  message?: string;
  referer?: string;
}

export const useErrorParams = () => {
  const params = useParams() as Record<string, string | undefined>;
  const search = useSearchParams();
  const paramsCode = params['code'];

  const result: ErrorParams = useMemo(() => {
    return {
      code: paramsCode ?? ErrorInfo.Unknown.code,
      name: orUndefined(search.get('name')),
      message: orUndefined(search.get('message')),
      referer: orUndefined(search.get('referer'))
    };
  }, [paramsCode, search]);

  return result;
};
