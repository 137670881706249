import { Client } from '../models';
import {
  Configuration,
  SigninRedirectState,
  SignoutRedirectState
} from '../types';

type RedirectConfiguration = Omit<Configuration, 'popupWindowFeatures'>;

export class RedirectClient extends Client<RedirectConfiguration> {
  public override async signIn(
    state: SigninRedirectState = {},
    silent?: boolean
  ) {
    try {
      await this._authenticator.signin(silent ? 'silent' : 'redirect', state);
    } catch (err) {
      console.error('RedirectClient:', 'failed to signin user', state);
    }
  }

  public override async signOut(state?: SignoutRedirectState) {
    try {
      this._authenticator.signout('redirect', state);
    } catch (err) {
      console.error('RedirectClient:', 'failed to signout user', err);
    }
  }

  public override async signOutCallback(url?: string) {
    return this._authenticator.signoutCallback('redirect', url);
  }
}
