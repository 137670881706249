export class UnknownError<T = unknown> extends Error {
  public static type = 'UnknownError';

  public readonly data?: T;

  public constructor(message?: string, context?: T) {
    super(message);
    this.data = context;
  }
}
