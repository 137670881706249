import { PropsWithChildren, useReducer } from 'react';

import {
  AuthClientContext,
  AuthStateContext,
  useEventBindings,
  useHydrateState
} from '../hooks';
import type { Client } from '../models/Client';
import { DEFAULT_AUTH_STATE, reducer } from '../state';

import { AccessTokenMiddleware } from './AccessTokenMiddleware';

type Props = {
  client: Client;
};

export const AuthClientProvider = ({
  children,
  client
}: PropsWithChildren<Props>) => {
  // TODO: use xstate
  const [state, dispatch] = useReducer(reducer, DEFAULT_AUTH_STATE);

  useEventBindings(client, dispatch);
  useHydrateState(client, dispatch);

  return (
    <AuthClientContext.Provider value={client}>
      <AuthStateContext.Provider value={state}>
        <AccessTokenMiddleware />
        {children}
      </AuthStateContext.Provider>
    </AuthClientContext.Provider>
  );
};
