import { useEffect } from 'react';
import { Navigate, useMatch } from 'react-router-dom';

import { ErrorInfo, ErrorRoute } from '../models';

import { HostReportErrors } from './HostReportErrors';
import { UnknownError } from './UnknownError';

interface Props {
  error: Error;
  info?: ErrorInfo;
}

export const ErrorRedirect = (props: Props) => {
  const { error, info } = props;
  //const referer = useLocation();
  const match = useMatch(ErrorRoute.path);
  const isErrorReferrer = match != null;

  useEffect(() => {
    if (isErrorReferrer) {
      HostReportErrors.rethrow(
        new UnknownError(
          `Multiple redirects detected in <ErrorRedirect />. This is typically caused by an error handler with an uncaught error.
            ${error.name} ${error.message}`,
          error
        )
      );
    }
  }, [isErrorReferrer, error]);

  if (isErrorReferrer) {
    return null;
  }

  return (
    <Navigate
      to={`${ErrorRoute.value(info?.code ?? ErrorInfo.Unknown.code)}?name=${
        error.name
      }&message=${error.message}`}
    />
  );
};
