import { TYPOGRAPHY } from '@irc/shared-theme';
import makeStyles from '@mui/styles/makeStyles';

const icon = {
  width: '1.5em',
  height: '1.5em'
};

const linearProgress = {
  height: '10px',
  borderRadius: '10px'
};

const image = {
  padding: '5px',
  border: `1px solid #FEFCFC`,
  borderRadius: '5px',
  boxShadow: '0px 2.02813px 2.02813px rgba(0, 0, 0, 0.25)'
};

export default makeStyles(({ palette }) => ({
  box: { padding: '1em' },
  linearProgress,
  errorLinearProgress: {
    '&.MuiLinearProgress-barColorPrimary': {
      backgroundColor: `${palette.error.light} !important`
    },
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: `${palette.error.light} !important`
    }
  },
  successLinearProgress: {
    '&.MuiLinearProgress-barColorPrimary': {
      backgroundColor: palette.success.dark
    }
  },
  uploadDetails: { display: 'flex', paddingBottom: '1em' },
  imageBox: { paddingRight: '1em' },
  icon: { marginLeft: 'auto' },
  retryButton: { padding: 0 },
  title: { display: 'flex' },
  name: { overflow: 'auto', maxHeight: '50px', wordBreak: 'break-all' },
  details: { minWidth: '110px', paddingRight: '1em' },
  status: {
    overflow: 'auto',
    maxHeight: '70px',
    fontSize: TYPOGRAPHY.bodyXS.fontSize,
    wordBreak: 'break-word'
  },
  successIcon: {
    ...icon,
    color: palette.success.dark
  },
  failIcon: {
    ...icon,
    color: palette.error.main
  },
  redoIcon: {
    width: '1em',
    height: '1em',
    color: 'inherit'
  },
  uploadProgress: {
    backgroundColor: 'white',
    border: `1px solid ${palette.divider}`,
    borderRadius: '5px'
  },
  image: {
    ...image,
    objectFit: 'cover'
  },
  imagePending: {
    opacity: 0.5,
    objectFit: 'cover',
    ...image
  },
  progressBar: {
    '&[aria-valuenow="0"]': {
      '& > $progressBarInner': {
        transition: 'none'
      }
    }
  },
  progressBarInner: {}
}));
