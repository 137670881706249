import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { ErrorInfo, GenericError } from '@irc/shared-components-error';
import { useAuthClient } from '@irc/shared-services-auth';
import { styled } from '@mui/material/styles';

export type SignOutState = {
  redirectUri: string;
};

type Props = {
  errorComponent?: typeof GenericError;
  onSignOut?: (state?: SignOutState) => void;
};

const Root = styled('div')`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignOutCallbackHandler = ({
  children,
  errorComponent,
  onSignOut
}: PropsWithChildren<Props>) => {
  const client = useAuthClient();
  const [callbackError, setCallbackError] = useState<Error | null>(null);
  const ErrorReporter = errorComponent || GenericError;

  const handleSignOutCallback = useCallback(() => {
    client
      .signOutCallback()
      .then((state) => onSignOut?.(state as SignOutState))
      .catch(setCallbackError);
  }, [client, onSignOut, setCallbackError]);

  useEffect(() => {
    handleSignOutCallback();
  }, [handleSignOutCallback]);

  return (
    <Root>
      {callbackError ? (
        <ErrorReporter
          info={ErrorInfo.Authentication}
          message={callbackError?.message}
        />
      ) : (
        children
      )}
    </Root>
  );
};
