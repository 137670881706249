import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette }) => ({
  input: {
    borderColor: palette.divider,
    '&.MuiInputBase-adornedStart': {
      paddingLeft: 0
    },
    '&.MuiInputBase-adornedEnd': {
      paddingRight: 0
    }
  },
  adornment: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));
