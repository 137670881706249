import Helmet from 'react-helmet';

type Props = {
  containerId: string;
  authKey?: string;
  previewId: string;
};

export const Analytics = ({ containerId, authKey, previewId }: Props) => {
  return containerId && authKey && previewId
    ? (
        <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${authKey}&gtm_preview=env-${previewId}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${containerId}');
          `
            }
          ]}
        />
      ) ?? null
    : null;
};
