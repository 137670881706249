import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { ErrorInfo, GenericError } from '@irc/shared-components-error';
import { useAuthClient, User } from '@irc/shared-services-auth';
import { styled } from '@mui/material/styles';

export type SignInState = {
  redirectUri: string;
};

type Props = {
  errorComponent?: typeof GenericError;
  onSignIn?: (user: User | null) => void;
};

const Root = styled('div')`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignInCallbackHandler = ({
  children = null,
  errorComponent,
  onSignIn
}: PropsWithChildren<Props>) => {
  const client = useAuthClient();
  const [callbackError, setCallbackError] = useState<Error | null>(null);
  const ErrorReporter = errorComponent || GenericError;

  const handleCallback = useCallback(() => {
    client.signInCallback().then(onSignIn).catch(setCallbackError);
  }, [client, onSignIn, setCallbackError]);

  useEffect(() => {
    handleCallback();
  }, [handleCallback]);

  return (
    <Root>
      {callbackError ? (
        <ErrorReporter
          info={ErrorInfo.Authentication}
          message={callbackError?.message}
        />
      ) : (
        children
      )}
    </Root>
  );
};
