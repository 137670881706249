import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthClient, useAuthState } from '@irc/shared-services-auth';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { PageLayout } from '../../app/PageLayout';
import LandingPageImage from '../../assets/landing-page.png';

const SignInButton = styled(Button)`
  font-size: 1.8rem;
  border: 1px solid ${({ theme }) => theme.palette.text.primary};
`;

const Wallpaper = styled('div')`
  background-image: url('${LandingPageImage}');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  margin: 0 -16px; // remove padding applied in App.tsx to all container pages
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const SignIn = () => {
  const session = useAuthClient();
  const { status } = useAuthState();

  const handleLoginClick = React.useCallback(async () => {
    await session.signIn();
  }, [session]);

  return status === 'authenticated' ? (
    <Navigate replace to='/home' />
  ) : (
    <PageLayout>
      <Wallpaper>
        <Paper elevation={5}>
          <SignInButton
            color='inherit'
            variant='contained'
            onClick={handleLoginClick}
          >
            Sign In
          </SignInButton>
        </Paper>
      </Wallpaper>
    </PageLayout>
  );
};
