import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions
} from 'msw';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { client } from '../client';
import * as Types from '../models';
export type GetLocationsQueryVariables = Types.Exact<{
  locationTypes?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>
  >;
}>;

export type GetLocationsQuery = {
  location: {
    all?: {
      nodes?: Array<{
        id: any;
        name: string;
        shortName: string;
        address?: {
          city: string;
          line1: string;
          state: string;
          zip5: string;
        } | null;
        capabilities: Array<{ name: string; description?: string | null }>;
        locationIdentifiers?: {
          schedulerCode?: string | null;
          tableLocationCode?: string | null;
        } | null;
        locationTypes: Array<{ shortCode: string }>;
        geofence: { centerPoint: { latitude: number; longitude: number } };
      }> | null;
    } | null;
  };
};

export const GetLocationsDocument = `
    query GetLocations($locationTypes: [String]) {
  location {
    all(
      first: 300
      where: {and: [{status: {eq: Active}}, {locationTypes: {some: {shortCode: {in: $locationTypes}}}}]}
    ) {
      nodes {
        id
        name
        shortName
        address {
          city
          line1
          state
          zip5
        }
        capabilities {
          name
          description
        }
        locationIdentifiers {
          schedulerCode
          tableLocationCode
        }
        locationTypes {
          shortCode
        }
        geofence {
          centerPoint {
            latitude
            longitude
          }
        }
      }
    }
  }
}
    `;
export const useGetLocationsQuery = <
  TData = GetLocationsQuery,
  TError = unknown
>(
  variables?: GetLocationsQueryVariables,
  options?: UseQueryOptions<GetLocationsQuery, TError, TData>
) =>
  useQuery<GetLocationsQuery, TError, TData>(
    variables === undefined ? ['GetLocations'] : ['GetLocations', variables],
    client<GetLocationsQuery, GetLocationsQueryVariables>(
      GetLocationsDocument,
      variables
    ),
    options
  );

useGetLocationsQuery.getKey = (variables?: GetLocationsQueryVariables) =>
  variables === undefined ? ['GetLocations'] : ['GetLocations', variables];

useGetLocationsQuery.fetcher = (
  variables?: GetLocationsQueryVariables,
  options?: RequestInit['headers']
) =>
  client<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    variables,
    options
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLocationsQuery(
 *   ({ query, variables }) => {
 *     const { locationTypes } = variables;
 *     return HttpResponse.json({
 *       data: { location }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetLocationsQuery = (
  resolver: GraphQLResponseResolver<
    GetLocationsQuery,
    GetLocationsQueryVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.query<GetLocationsQuery, GetLocationsQueryVariables>(
    'GetLocations',
    resolver,
    options
  );
