import sortBy from 'lodash/sortBy';

import { Location } from '../models';
import { GetLocationsQuery } from '../operations';

import { useLocations } from './useLocations';

export const parentLocationSelector = (query: GetLocationsQuery) => {
  return sortBy(query.location.all?.nodes as Location[], 'shortName');
};

export const useParentLocations = (locationTypes?: string[]) => {
  return useLocations(locationTypes, parentLocationSelector);
};
