import { isEqual, StringComparison } from '../utils/strings';

interface ErrorInfoProperties {
  name?: string;
  severity?: number;
}

export class ErrorInfo<Code extends string = string> {
  public static readonly Unknown = new ErrorInfo('Unknown');
  public static readonly Validation = new ErrorInfo('Validation');
  public static readonly DependencyFailure = new ErrorInfo('DependencyFailure');
  public static readonly BusinessLogic = new ErrorInfo('BusinessLogic');
  public static readonly NotFound = new ErrorInfo('NotFound');
  public static readonly Conflict = new ErrorInfo('Conflict');
  public static readonly Authorization = new ErrorInfo('Authorization');
  public static readonly Authentication = new ErrorInfo('Authentication');
  public static readonly Unhandled = new ErrorInfo('Unhandled');

  public static readonly All = [
    ErrorInfo.Unknown,
    ErrorInfo.Validation,
    ErrorInfo.DependencyFailure,
    ErrorInfo.BusinessLogic,
    ErrorInfo.NotFound,
    ErrorInfo.Conflict,
    ErrorInfo.Authorization,
    ErrorInfo.Authentication,
    ErrorInfo.Unhandled
  ];

  public readonly code: Code;
  public readonly name: string;
  public readonly severity: number;

  public constructor(code: Code, properties?: ErrorInfoProperties) {
    this.code = code;
    this.name = properties?.name ?? code;
    this.severity = properties?.severity ?? 0;
  }

  public static from(code: string): ErrorInfo {
    return (
      ErrorInfo.All.find((x) =>
        isEqual(code, x.code, StringComparison.IgnoreCase)
      ) ?? ErrorInfo.Unknown
    );
  }

  public equals(other: ErrorInfo | null | undefined) {
    if (other == null) {
      return false;
    }

    return isEqual(this.code, other.code, StringComparison.IgnoreCase);
  }
}
