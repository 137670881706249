import React, { Fragment, PropsWithChildren } from 'react';

import { Link } from '@irc/carli-components';
import { Menu } from '@irc/shared-components-menu';
import UserIcon from '@mui/icons-material/AccountCircleOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(ListItem)`
  max-width: 320px;
  &:hover {
    background-color: transparent;
  }

  & .MuiListItem-secondaryAction {
    padding-right: 56px;
  }
`;

const IconContainer = styled(ListItemIcon)`
  min-width: 48px;
`;

const StyledText = styled(ListItemText)`
  & .MuiListItemText-primary,
  & .MuiListItemText-secondary {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: inherit;
  }
`;

const StyledActions = styled(ListItemSecondaryAction)`
  cursor: pointer;
`;
export interface UserMenuProps {
  title: string;
  subtitle?: string;
}

export const UserMenu = ({
  title,
  subtitle,
  children
}: PropsWithChildren<UserMenuProps>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (
    evt: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    setAnchorEl(evt.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  return (
    <Fragment>
      <Root
        {...({ button: true } as any)}
        ContainerComponent='div'
        onClick={openMenu}
        data-qa='menu-container'
      >
        <IconContainer>
          <UserIcon htmlColor='white' fontSize='large' />
        </IconContainer>
        <StyledText
          primary={title}
          secondary={subtitle}
          data-qa='header-title'
        />
        <StyledActions onClick={openMenu}>
          {open ? (
            <ExpandLess htmlColor='white' />
          ) : (
            <ExpandMore htmlColor='white' />
          )}
        </StyledActions>
      </Root>
      <Menu
        hideBackdrop
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
      >
        {children}
        <Divider />
        <MenuItem>
          <Link
            to='/logout'
            underline='none'
            color='textPrimary'
            data-qa='header-sign-out'
          >
            Sign Out
          </Link>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
