export interface InlineManualTracking {
  /**
   * uid is mandated, all other properties are optional
   */
  uid: string;
  email?: string;
  username?: string;
  name?: string;
  created?: Date;
  updated?: Date;
  roles?: string[];
  group?: string;
  plan?: string;
}

declare global {
  interface Window {
    inlineManualTracking: InlineManualTracking;
    createInlineManualPlayer: (data: Record<string, unknown>) => unknown;
    inlineManualPlayerData: Record<string, unknown>;
  }
}

export const PLAYER_ERROR = 'unable to find createInlineManualPlayer';
export const DATA_ERROR = 'unable to find inlineManualPlayerData';

export const getPlayerUrl = (apiKey: string) =>
  `//cdn.inlinemanual.com/embed/player.${apiKey}.bare.js`;

export const loadAsync = (resourceUrl: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script');
      const loadCallback = () => {
        script.removeEventListener('load', loadCallback);
        resolve();
      };
      script.addEventListener('load', loadCallback);
      script.src = resourceUrl;
      script.async = true;
      document.body.appendChild(script);
    } catch (err) {
      reject(err);
    }
  });
};

export const createPlayer = (user?: InlineManualTracking) => {
  if (!('createInlineManualPlayer' in window)) {
    throw new Error(PLAYER_ERROR);
  }

  if (!('inlineManualPlayerData' in window)) {
    throw new Error(DATA_ERROR);
  }

  if (user) {
    window.inlineManualTracking = user;
  }

  if (!('inline_manual_player' in window)) {
    window.createInlineManualPlayer(window.inlineManualPlayerData);
  }
};
