import { NavLink } from 'react-router-dom';

import { CautionBubble } from './CautionBubble';
import { CenteredVerticalLayout } from './CenteredVerticalLayout';
import { Header, Summary } from './styles';

export const Unauthorized = () => {
  return (
    <CenteredVerticalLayout>
      <Header>How did you end up here?!</Header>
      <CautionBubble />
      <Summary>
        You&apos;ve reached a page you don&apos;t have access to.
        <br />
        You best be returning <NavLink to='/'>home</NavLink>!
      </Summary>
    </CenteredVerticalLayout>
  );
};
