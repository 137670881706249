import React, { useContext } from 'react';

export const DEFAULT_CONTEXT_ERROR =
  "Couldn't locate context. Is your component inside a context provider?";

export const useContextWithError = <T>(
  context: React.Context<T>,
  error = DEFAULT_CONTEXT_ERROR
) => {
  const nextContext = useContext(context);

  if (nextContext === undefined) {
    throw new Error(error);
  }

  return nextContext;
};
