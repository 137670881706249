import { PropsWithChildren } from 'react';

import { useAuthState, User } from '@irc/shared-services-auth';

import { Challenge } from '../Challenge';

export const RequireAuthentication = ({
  requiredScopes,
  children
}: PropsWithChildren<{ requiredScopes?: string[] }>) => {
  const { isHydrated, user } = useAuthState();

  if (!isHydrated) return null;
  if (!user || isMissingRequiredScope(user, requiredScopes))
    return <Challenge />;

  return <>{children}</>;
};

function isMissingRequiredScope(user: User, requiredScopes?: string[]) {
  if (!requiredScopes) return false;
  const userScopes = user.scope?.split(' ') ?? [];
  return requiredScopes.some((s) => !userScopes.includes(s));
}
