import React from 'react';

import { useAppState } from '@irc/carli-components';
import { useAuthState } from '@irc/shared-services-auth';
import { Location, useParentLocations } from '@irc/shared-services-location';
import MenuItem from '@mui/material/MenuItem';

import { filterLocations } from '../../utils';
import { UserMenu } from '../UserMenu';

const LOCATION_TYPES = ['IC', 'OL', 'MH', 'HB'];

export const Menu = () => {
  const { user } = useAuthState();
  const [appState, setAppState] = useAppState();
  const profileName = user?.profile?.name || '';

  const { data: locations } = useParentLocations(LOCATION_TYPES);

  const locationsForLayout = React.useMemo(() => {
    if (!locations) return [] as Location[];
    return filterLocations(locations as Location[]);
  }, [locations]);

  const handleLocationChange = (shortName?: string | null) => () => {
    if (shortName) {
      setAppState((prevState) => ({
        ...prevState,
        selectedLocationCode: shortName
      }));
    }
  };

  return (
    <UserMenu
      title={profileName}
      subtitle={appState?.selectedLocationCode ?? 'Select Location'}
    >
      {locationsForLayout.map(({ shortName }) => (
        <MenuItem
          key={shortName}
          selected={shortName === appState?.selectedLocationCode}
          onClick={handleLocationChange(shortName)}
          data-qa={`menu-item-${shortName}`}
        >
          {shortName}
        </MenuItem>
      ))}
    </UserMenu>
  );
};
