import { createContext } from 'react';

import { useContextWithError } from '@irc/shared-utils-react';

import type { Client } from '../models';

export const AuthClientContext = createContext<Client | undefined>(undefined);

export const AUTH_CLIENT_ERROR =
  "Couldn't locate auth client. Is your component inside the AuthClientProvider?";

export const useAuthClient = () =>
  useContextWithError(AuthClientContext, AUTH_CLIENT_ERROR);
