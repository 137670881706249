import React from 'react';
import { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import { useDeepCompareEffect } from 'react-use';

import { useAuthState } from '@irc/shared-services-auth';

import { LaunchDarklyIdentify } from '../LaunchDarklyIdentify';

const DEFAULT_CUSTOM: Record<string, unknown> = {}; // memoized obj

type Props = {
  custom?: Record<string, unknown>;
  onDone?: () => void;
};

export const LaunchDarklyIdentifySessionUser = ({
  children,
  custom = DEFAULT_CUSTOM,
  onDone
}: React.PropsWithChildren<Props>) => {
  const { user } = useAuthState();
  const [context, setContext] = React.useState<LDSingleKindContext>();

  useDeepCompareEffect(() => {
    if (user?.profile?.email) {
      setContext({
        kind: 'user',
        key: user?.profile?.email,
        name: user?.profile?.name,
        firstName: user?.profile?.given_name,
        lastName: user?.profile?.family_name,
        email: user?.profile?.email,
        ...custom
      });
    }
  }, [
    user?.profile?.email,
    user?.profile?.name,
    user?.profile?.given_name,
    user?.profile?.family_name,
    custom
  ]);

  return (
    <LaunchDarklyIdentify context={context} onDone={onDone}>
      {children}
    </LaunchDarklyIdentify>
  );
};
