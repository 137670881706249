import { Dispatch, useCallback, useEffect } from 'react';

import { Client, User } from '../models';
import { AuthAction } from '../state';

/* NOTE: this hook is a parity of the same hook in GB if we ever decide to leverage that lib */
export const useHydrateState = (
  client: Client,
  dispatch: Dispatch<AuthAction>
) => {
  const tryDispatch = useCallback(
    (signal: AbortSignal, action: AuthAction) => {
      if (!signal.aborted) {
        dispatch(action);
      }
    },
    [dispatch]
  );

  const tryHydrate = useCallback(
    async (signal: AbortSignal) => {
      tryDispatch(signal, { type: 'loadingUser' });

      try {
        const user = await client.getUser();
        let action: AuthAction = { type: 'userExpired' };

        if (user?.isAuthenticated) {
          action = { type: 'userLoaded', payload: user as User };
        }

        tryDispatch(signal, action);
      } catch (e) {
        tryDispatch(signal, { type: 'loadingUserError', payload: e as Error });
      } finally {
        tryDispatch(signal, { type: 'userHydrated' });
      }
    },
    [client, tryDispatch]
  );

  useEffect(() => {
    const ac = new AbortController();
    tryHydrate(ac.signal);

    return () => ac.abort();
  }, [tryHydrate]);
};
