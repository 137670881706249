import { PaletteOptions } from '@mui/material/styles';

/* https://showroom.cvnacorp.com/showroom-css-theme */
export const PALETTE: PaletteOptions = Object.freeze({
  background: {
    default: '#F4F8FA'
  },
  grey: {
    200: '#F4F8FA',
    300: '#D9DDE9',
    400: '#AAB1C7',
    600: '#757989'
  },
  primary: {
    main: '#1971C2',
    dark: '#145591',
    light: '#00AED9',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#FEB948',
    dark: '#FF9600',
    light: '#FED37F'
  },
  // there really is no "info" palette in Carvana
  info: {
    main: '#045489',
    dark: '#183558',
    light: '#00619E',
    contrastText: '#FFFFFF'
  },
  error: {
    main: '#D6002B',
    dark: '#A30000',
    light: '#F65B5B'
  },
  warning: {
    main: '#FEB948',
    dark: '#FF9600',
    light: '#FED37F'
  },
  success: {
    main: '#20A91E',
    dark: '#008400',
    light: '#64CB63'
  },
  text: {
    primary: '#122946',
    secondary: '#757989',
    disabled: '#AAB1C7'
  },
  divider: '#8292A7',
  action: {
    disabled: '#AAB1C7',
    disabledBackground: '#D9DDE9'
  }
});
