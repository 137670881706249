import React from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';

type Props = {
  messages?: (string | undefined)[];
  onClose?: () => void;
  severity?: 'warning' | 'error' | 'info';
  title?: string;
};

export function Banner({ messages, onClose, severity, title }: Props) {
  if (!messages?.length) return null;

  return (
    <Root onClose={onClose} severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      <List role='list'>
        {messages.map((message, index) => (
          <ListItem role='listitem' key={index}>
            {message}
          </ListItem>
        ))}
      </List>
    </Root>
  );
}

const Root = styled(Alert)`
  .MuiAlert-action {
    display: block;
    margin-top: 6px;
  }
`;

const List = styled('ul')`
  padding: 0;
`;

const ListItem = styled('li')`
  list-style-type: disc;
  display: list-item;
  font-size: 16px;
  margin: 15px 0;

  :first-of-type:last-child {
    list-style-type: none;
    margin: 0;
  }
`;
