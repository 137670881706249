export const VIN_REGEX = new RegExp(
  /^([A-HJ-NPR-Z\d]{3})([A-HJ-NPR-Z\d]{5})([\dX])(([A-HJ-NPR-Z\d])([A-HJ-NPR-Z\d])([A-HJ-NPR-Z\d]{6}))$/,
  'i'
);
export const STOCK_REGEX = new RegExp(/^[2,3][0-9]{9}$/);

export enum SearchFieldResultType {
  Vin,
  StockNumber
}

export function sanitizeInput(value: string): string {
  let nextValue = value;

  if (nextValue.length >= 18 && nextValue.toLowerCase().startsWith('i')) {
    nextValue = nextValue.slice(1);
  }

  return nextValue.trim();
}

export function isValidStockOrVinLength(value: string): boolean {
  return (
    value.length === 10 ||
    value.length === 17 ||
    (value.length === 18 && value.toLowerCase().startsWith('i'))
  );
}

export function isStockNumber(value: string): boolean {
  return value.length === 10 && STOCK_REGEX.test(value);
}

export function isVin(value: string): boolean {
  return value.length === 17 && VIN_REGEX.test(value);
}
