import { ReactNode } from 'react';
import { isNil } from 'lodash';

import { ErrorInfo } from '../models/ErrorInfo';

import { CautionBubble } from './CautionBubble';
import { CenteredVerticalLayout } from './CenteredVerticalLayout';
import { Header, Highlight, Summary } from './styles';

interface Props {
  info: ErrorInfo;
  message?: string;
}

export const GenericError = (props: Props) => {
  const { info, message } = props;
  let summary: ReactNode = (
    <>
      We are not really sure what went wrong, but an error code of{' '}
      <Highlight>{info.code}</Highlight> was returned.
    </>
  );

  if (!isNil(message)) {
    summary = (
      <>
        We are not really sure what went wrong, but an error code of{' '}
        <Highlight>{info.code}</Highlight> with the the message{' '}
        <Highlight>&apos;{message}&apos;</Highlight> was returned.
      </>
    );
  }

  return (
    <CenteredVerticalLayout>
      <Header>Oops, something went wrong!</Header>
      <CautionBubble />
      <Summary>{summary}</Summary>
    </CenteredVerticalLayout>
  );
};
