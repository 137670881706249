import MuiMenu, { MenuProps } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

const StyledMenu = styled(MuiMenu, {
  shouldForwardProp: (prop) => prop !== 'arrow'
})<{ arrow?: boolean }>`
  .MuiPaper-root {
    min-width: 192px;
    ${({ arrow, theme }) =>
      arrow
        ? `
      overflow: unset;
      margin-top: 12px;

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        right: 19px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${theme.palette.background.paper};
    `
        : ''}
  }
`;

type Props = MenuProps & {
  arrow?: boolean;
};

export const Menu = ({ children, ...props }: Props) => {
  return (
    <StyledMenu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...props}
    >
      {children}
    </StyledMenu>
  );
};
