import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

type Props = {
  clientId?: string;
  flags?: Record<string, boolean>;
};

type ProviderType =
  | Awaited<ReturnType<typeof asyncWithLDProvider>>
  | typeof Fragment;

export const LaunchDarklyProvider = ({
  children,
  clientId,
  flags
}: PropsWithChildren<Props>) => {
  const providerRef = useRef<ProviderType>(Fragment);
  const [isLoaded, setIsLoaded] = useState(false);

  const initialize = useCallback(async () => {
    if (clientId && !isLoaded) {
      try {
        providerRef.current = await asyncWithLDProvider({
          clientSideID: clientId,
          reactOptions: { useCamelCaseFlagKeys: false },
          flags
        });
      } catch (err) {
        console.error(
          'An error occurred initiating LaunchDarkly, continuing without feature flag support.'
        );
      }
    } else {
      console.warn(
        'LaunchDarkly is not configured, continuing without feature flag support.'
      );
    }
    setIsLoaded(true);
  }, [flags, clientId, isLoaded]);

  useEffect(() => {
    if (!isLoaded) initialize();
  }, [initialize, isLoaded]);

  const Provider = providerRef.current;
  return isLoaded ? <Provider>{children}</Provider> : null;
};
