import { useToastContainer } from 'react-toastify';

import Alert from '@mui/material/Alert';
import MUISnackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

import {
  mapOriginToPosition,
  mapPositionToOrigin,
  mapTypeToSeverity
} from '../utils';

const AlertContainer = styled(Alert)`
  width: 500px;
  padding: 10px 20px;
  align-items: center;
`;
const MessageText = styled('span')`
  font-size: 20px;
`;

type Props = {
  autoHide?: number;
  id?: string;
  origin?: SnackbarOrigin;
  stickyErrors?: boolean; // TODO: defaulting to true, for now, to avoid cascading changes in other apps
};

export const Snackbar = ({
  autoHide = 4000,
  id,
  origin,
  stickyErrors = true
}: Props) => {
  const { getToastToRender, containerRef, isToastActive } = useToastContainer({
    limit: 1,
    enableMultiContainer: Boolean(id),
    containerId: id,
    position: mapOriginToPosition(origin)
  });

  return (
    <div ref={containerRef} id={id}>
      {getToastToRender((position, toastList) => {
        const toastOrigin = mapPositionToOrigin(position);
        return toastList.map(({ content, props: toastProps }, i) => (
          <MUISnackbar
            disableWindowBlurListener
            key={`notification-${toastProps.key}`}
            open={isToastActive(toastProps.toastId)}
            anchorOrigin={toastOrigin}
            autoHideDuration={
              toastProps.type === 'error' && stickyErrors ? undefined : autoHide
            }
            onClose={toastProps.deleteToast}
            ClickAwayListenerProps={{
              mouseEvent: false
            }}
          >
            <AlertContainer
              onClose={toastProps.deleteToast}
              severity={mapTypeToSeverity(toastProps.type)}
            >
              <MessageText>{content as string}</MessageText>
            </AlertContainer>
          </MUISnackbar>
        ));
      })}
    </div>
  );
};
