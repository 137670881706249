import { QueryObserverOptions } from '@tanstack/react-query';

import {
  GetLocationsQuery,
  useGetLocationsQuery
} from '../operations/getLocations';

export const useLocations = <ReturnType = GetLocationsQuery>(
  locationTypes: string[] = [],
  select?: QueryObserverOptions<
    GetLocationsQuery,
    unknown,
    ReturnType
  >['select']
) => {
  return useGetLocationsQuery(
    {
      locationTypes
    },
    {
      select
    }
  );
};
