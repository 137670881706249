// TODO: not really a fan of defining route paths in a service
export const DEFAULT_AUTH_SIGNIN = '/signin';
export const DEFAULT_AUTH_SIGNIN_CALLBACK = '/signin/callback';
export const DEFAULT_AUTH_SIGNOUT = '/logout';
export const DEFAULT_AUTH_SIGNOUT_CALLBACK = '/logout/callback';
export const DEFAULT_AUTH_SILENT_RENEW_CALLBACK = '/silentrenew';

export const DEFAULT_AUTH_SCOPES = Object.freeze([
  'openid',
  'profile',
  'employee_profile',
  'email'
]);

export const joinPopupFeatures = (features: object = {}) => {
  return Object.entries(features)?.reduce((config, [key, value], idx) => {
    return config.concat(`${idx === 0 ? '' : ','}${key}=${value}`);
  }, '');
};

export const joinScopes = (scopes: string[] = []) => {
  return DEFAULT_AUTH_SCOPES.concat(scopes)
    .filter((val, i, arr) => arr.indexOf(val) === i)
    .join(' ');
};

export const parseScopes = (scopes: string) => scopes.split(' ');

export const getMissingScopes = (oldScopes: string[], newScopes: string[]) =>
  newScopes.filter((scope) => !oldScopes.includes(scope));

export const isAbsoluteUrl = (url: string) => /^(?:[a-z]+:)?\/\//i.test(url);

export const toRelativeUrl = (url = '', baseUrl = '') => {
  if (isAbsoluteUrl(url)) {
    url = url.substring(baseUrl.length);
  }

  return url[0] === '/' ? url : `/${url}`;
};

export const toRedirectUriState = (redirectUri: string) => ({
  state: { redirectUri }
});
