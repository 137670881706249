declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

export const pushToDataLayer = (
  event: string,
  params?: Record<string, unknown>
) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event,
      ...params
    });
  }
};

export const pushDefectDataToGA = (defectData: {
  defectCategory: { label?: string | null; defectCategoryId?: number };
  defectType: { label?: string | null; defectTypeId?: number };
  defectLocation: { label?: string | null; defectLocationId?: number };
}) => {
  pushToDataLayer('defect_suggestion_click', {
    defect_category: defectData.defectCategory?.label,
    defect_location: defectData.defectLocation?.label,
    defect_type: defectData.defectType?.label
  });
};

export const pushInspectionSubmissionToGA = (vin: string) => {
  pushToDataLayer('inspection_submission', { inspection_vin: vin });
};
