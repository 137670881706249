import { useCallback, useEffect } from 'react';
import Axios, { AxiosError } from 'axios';

import { useAuthClient } from '../hooks/useAuthClient';

export const AccessTokenMiddleware = () => {
  const client = useAuthClient();

  const getAccessToken = useCallback(async () => {
    const user = await client.getUser();
    return user?.access_token;
  }, [client]);

  useEffect(() => {
    Axios.interceptors.request.use(
      async (config) => {
        const token = await getAccessToken();
        config.headers.set('Authorization', `Bearer ${token}`);
        return config;
      },
      (err: AxiosError) => Promise.reject(err)
    );
  }, [getAccessToken]);

  return null;
};
