import { BREAKPOINT, createOverrides, PALETTE, TYPOGRAPHY } from './lib/theme';
import { createTheme } from './lib/utils';

export * from './lib/theme';
export * from './lib/ThemeProvider';
export * from './lib/utils';

const theme = createTheme({
  palette: PALETTE,
  breakpoints: { values: BREAKPOINT },
  typography: {
    h1: TYPOGRAPHY.headerXXL,
    h2: TYPOGRAPHY.headerXL,
    h3: TYPOGRAPHY.headerL,
    h4: TYPOGRAPHY.headerM,
    h5: TYPOGRAPHY.headerS,
    h6: TYPOGRAPHY.headerXS,
    subtitle1: TYPOGRAPHY.bodyM,
    subtitle2: TYPOGRAPHY.bodyS,
    body1: TYPOGRAPHY.bodyM,
    body2: TYPOGRAPHY.bodyS
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },
    // start v5 migration
    MuiIconButton: {
      defaultProps: {
        size: 'large'
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary'
      }
    }
    // end v5 migration
  }
});

theme.components = createOverrides(theme);

export default theme;
