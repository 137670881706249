import React, { useCallback } from 'react';

import { useAuthClient } from '@irc/shared-services-auth';

export const SignOut = () => {
  const client = useAuthClient();

  const signout = useCallback(async () => {
    await client.signOut();
  }, [client]);

  React.useEffect(() => {
    signout();
  }, [signout]);

  return <div>Redirecting...</div>;
};
