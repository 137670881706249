import { Theme } from '@mui/material';

/* TODO: update breakpoint impl */
export const BREAKPOINT: Theme['breakpoints']['values'] = Object.freeze({
  xs: 0,
  sm: 414,
  md: 768,
  lg: 1024,
  xl: 1280
});
