/*
environment proxy file used in conjunction with react-inject-env to produce runtime vars
*/

declare global {
  interface Window {
    env: NodeJS.ProcessEnv;
  }
}

const target = { ...process.env, ...window.env };

export const env = new Proxy(target, {
  get(target, prop: string) {
    return target[`NX_PUBLIC_${prop}`] || target[prop];
  }
});
