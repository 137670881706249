import React from 'react';

import { Storage } from '../../models';
import { LocalStorage } from '../../stores';

export const useStorageItem = <Schema>(
  key: keyof Schema,
  initialValue?: Schema[keyof Schema],
  instance?: Storage<Schema>
) => {
  if (!key) {
    throw new Error('useStorageItem key cannot be falsy');
  }

  const store = React.useMemo(
    () => instance || new LocalStorage<Schema>({ name: 'irc' }),
    [instance]
  );

  const initializer = React.useRef(initialValue);
  const [state, setState] = React.useState<Schema[keyof Schema] | undefined>(
    initialValue
  );

  const initialize = React.useCallback(async () => {
    try {
      const localStorageValue = await store.getItem(key);
      if (localStorageValue !== null) {
        setState(localStorageValue);
      } else {
        setState(initializer.current);
      }
    } catch (e) {
      console.error('useStorageItem:', e);
    }
  }, [key, store]);

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  const set = React.useCallback(
    async (valOrFunc: React.SetStateAction<Schema[keyof Schema]>) => {
      try {
        const nextState =
          typeof valOrFunc === 'function'
            ? (valOrFunc as (state: Schema[keyof Schema] | undefined) => void)(
                state
              )
            : valOrFunc;
        if (typeof nextState === 'undefined') return;
        await store.setItem(key, nextState).then(setState);
      } catch (e) {
        console.error('useStorageItem:', e);
      }
    },
    [key, store, state]
  );

  const remove = React.useCallback(async () => {
    try {
      await store.removeItem(key);
      setState(undefined);
    } catch (e) {
      console.error('useStorageItem:', e);
    }
  }, [key, store]);

  return [state, set, remove] as const;
};
