import React from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material/styles';

import { Menu } from '../Menu';
import { MenuItem } from '../MenuItem';

const Content = styled('div')`
  pointer-events: auto;
`;

const ParentMenuItem = styled(MenuItem)<{ open?: boolean }>`
  background-color: ${({ open, theme }) =>
    open ? theme.palette.action.hover : 'transparent'};
  justify-content: space-between;
`;

// Set pointer events to 'none' to prevent the invisible Popover div
// from capturing events for clicks and hovers
const NestedMenu = styled(Menu)`
  pointer-events: none;
`;

type Props = React.ComponentProps<typeof MenuItem> & {
  parentMenuOpen: boolean;
  label?: React.ReactNode;
  rightIcon?: React.ReactNode;
  ContainerProps?: React.HTMLAttributes<HTMLElement> &
    React.RefAttributes<HTMLElement | null>;
  button?: true;
};

export const NestedMenuItem = React.forwardRef<HTMLLIElement, Props>(
  (props, ref) => {
    const {
      parentMenuOpen,
      label,
      rightIcon = <KeyboardArrowRightIcon />,
      children,
      className,
      //tabIndex: tabIndexProp,
      ContainerProps: ContainerPropsProp = {},
      ...MenuItemProps
    } = props;

    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

    const menuItemRef = React.useRef<HTMLLIElement>(null);
    React.useImperativeHandle(ref, () => menuItemRef.current as HTMLLIElement);

    const containerRef = React.useRef<HTMLDivElement>(null);
    React.useImperativeHandle(
      containerRefProp,
      () => containerRef.current as HTMLDivElement
    );

    const menuContainerRef = React.useRef<HTMLDivElement>(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);

    // let tabIndex
    // if (!props.disabled) {
    //   tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1
    // }

    const handleMouseEnter = (evt: React.MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(true);

      if (ContainerProps?.onMouseEnter) {
        ContainerProps.onMouseEnter(evt);
      }
    };
    const handleMouseLeave = (evt: React.MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(false);

      if (ContainerProps?.onMouseLeave) {
        ContainerProps.onMouseLeave(evt);
      }
    };

    // Check if any immediate children are active
    const isSubmenuFocused = () => {
      const active = containerRef.current?.ownerDocument?.activeElement;

      if (menuContainerRef.current) {
        for (const child of Array.from(menuContainerRef.current.children)) {
          if (child === active) {
            return true;
          }
        }
      }

      return false;
    };

    const handleFocus = (evt: React.FocusEvent<HTMLElement>) => {
      if (evt.target === containerRef.current) {
        setIsSubMenuOpen(true);
      }

      if (ContainerProps?.onFocus) {
        ContainerProps.onFocus(evt);
      }
    };

    const handleKeyDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
      if (evt.key === 'Escape') return;

      if (isSubmenuFocused()) {
        evt.stopPropagation();
      }

      const active = containerRef.current?.ownerDocument?.activeElement;

      if (evt.key === 'ArrowLeft' && isSubmenuFocused()) {
        containerRef.current?.focus();
      }

      if (
        evt.key === 'ArrowRight' &&
        evt.target === containerRef.current &&
        evt.target === active
      ) {
        const firstChild = menuContainerRef.current
          ?.children?.[0] as HTMLElement;
        firstChild?.focus();
      }
    };

    return (
      <div
        {...ContainerProps}
        ref={containerRef}
        onFocus={handleFocus}
        //tabIndex={tabIndex}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleKeyDown}
      >
        <ParentMenuItem {...MenuItemProps} ref={menuItemRef}>
          {label}
          {rightIcon}
        </ParentMenuItem>
        <NestedMenu
          anchorEl={menuItemRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(isSubMenuOpen && parentMenuOpen)}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          hideBackdrop
          onClose={() => setIsSubMenuOpen(false)}
        >
          <Content ref={menuContainerRef}>{children}</Content>
        </NestedMenu>
      </div>
    );
  }
);
