import { ReactNode } from 'react';

import { styled } from '@mui/material/styles';

import { useErrorParams } from '../hooks/useErrorParams';
import { ErrorInfo } from '../models/ErrorInfo';

import { GenericError } from './GenericError';
import { NotFound } from './NotFound';
import { Unauthorized } from './Unauthorized';

export const Error = () => {
  const { code, message } = useErrorParams();
  const info = ErrorInfo.from(code);
  let content: ReactNode = null;

  if (info.equals(ErrorInfo.NotFound)) {
    content = <NotFound />;
  } else if (info.equals(ErrorInfo.Authorization)) {
    content = <Unauthorized />;
  } else {
    content = <GenericError info={info} message={message} />;
  }

  return <FullCenter>{content}</FullCenter>;
};

const FullCenter = styled('div')`
  display: flex;
  height: 100vh;
  width: 100%;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.common.white};
`;
