import isNil from 'lodash/isNil';

type OrUndefined<T> = T extends null ? undefined : T;
type OrNull<T> = T extends undefined ? null : T;

/**
 * Returns the value or undefined if value is nil.
 *
 * @param value
 * @returns
 */
export const orUndefined = <T>(value: T | null | undefined): OrUndefined<T> => {
  let result: T | undefined;

  if (!isNil(value)) {
    result = value;
  }

  return result as OrUndefined<T>;
};

/**
 * Returns the value or null if value is nil.
 *
 * @param value
 * @returns
 */
export const orNull = <T>(value: T | null | undefined): OrNull<T> => {
  let result: T | null = null;

  if (!isNil(value)) {
    result = value;
  }

  return result as OrNull<T>;
};

/**
 * Returns value iif test true.
 *
 * @param value
 * @param test
 * @returns
 */
export const valueIf = <T>(value: T, test?: boolean): T | undefined => {
  let result: T | undefined;

  if (test === true) {
    result = value;
  }

  return result;
};
