import { EventEmitter } from 'eventemitter3';
import LocalForage from 'localforage';
import { kebabCase, omitBy } from 'lodash';

import { DefaultSchema, Readable, Writeable } from '../types';

export type InitSchema = {
  name?: string;
  storeName?: string;
  description?: string;
};

export type StorageFacade<Schema> = Readable<Schema> & Writeable<Schema>;

export abstract class Storage<Schema = DefaultSchema>
  extends EventEmitter
  implements StorageFacade<Schema>
{
  protected _store: LocalForage = LocalForage;

  protected static _sanitize = (schema?: InitSchema): InitSchema => {
    if (!schema) return {};

    const sanitized = omitBy(schema, (val) => !val);
    if (sanitized.name) sanitized.name = kebabCase(sanitized.name);

    return sanitized;
  };

  public keys = async () => {
    return await this._store.keys();
  };

  public getItem = async <K extends keyof Schema>(id: K) => {
    return await this._store.getItem<Schema[K]>(id.toString());
  };

  public getItems = <K extends keyof Schema>(
    comparer?: (value: Schema[K]) => boolean
  ) => {
    return new Promise<Schema[K][]>((resolve) => {
      const data: Schema[K][] = [];
      this._store
        .iterate<Schema[K], void>((value) => {
          const add = comparer ? comparer(value) : true;
          if (add) data.push(value);
        })
        .then(() => resolve(data));
    });
  };

  public setItem = async <K extends keyof Schema>(
    key: K,
    data: Partial<Schema[K]>
  ) => {
    const item = await this._store.setItem<Schema[K] | undefined>(
      key.toString(),
      data as Schema[K]
    );
    this.emit('change');

    return item;
  };

  public removeItem = async <K extends keyof Schema>(key: K) => {
    const resp = await this._store.removeItem(key.toString());
    this.emit('change');

    return resp;
  };

  public clear = async () => {
    const resp = await this._store.clear();
    this.emit('change');
    return resp;
  };
}
