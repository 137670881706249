import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
  Theme,
  ThemeOptions
} from '@mui/material/styles';

export const createTheme = (options?: ThemeOptions) =>
  createMuiTheme({
    ...options,
    components: {
      ...options?.components,
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      }
    },
    typography: {
      ...options?.typography,
      fontFamily: [
        '"Brandon Text"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(',')
    }
  });

export const createResponsiveTheme = (theme: Theme) =>
  responsiveFontSizes(theme, {
    breakpoints: ['sm', 'md'],
    factor: 3
  });
