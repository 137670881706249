import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouteLinkProps
} from 'react-router-dom';

import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';

export type LinkProps = Pick<
  MuiLinkProps,
  'variant' | 'underline' | 'color' | 'classes' | 'children'
> &
  Pick<RouteLinkProps, 'to' | 'replace'>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <MuiLink {...props} ref={ref} component={RouterLink} />
));
