import React, { Fragment } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { env } from '@irc/shared-utils-environment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiDivider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

export interface CustomLink {
  id: string;
  onClick: (e: React.MouseEvent) => void;
  label: string;
}

type Props = {
  showCarliLink?: boolean;
  customLinks?: CustomLink[];
};

const Urls = {
  SUPPORT: env.NX_PUBLIC_CARLI_SUPPORT_REDIRECT ?? '',
  REQUEST_ACCESS:
    'https://carvana.service-now.com/scp/?id=sc_cat_item&table=sc_cat_item&sys_id=42fea2019741e910a79270700153afba',
  HEY_CARLI: env.NX_PUBLIC_CARLI_REDIRECT ?? ''
};

const Root = styled('div')`
  text-align: right;
`;

const NavBar = styled(Box)`
  background-color: ${({ theme }) => theme.palette.text.primary};
  align-items: center;
  height: 56px;
  display: flex;
  justify-content: center;
`;

const Divider = styled(MuiDivider)`
  height: 50%;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})<{ $active?: boolean }>`
  text-transform: none;
  color: ${({ theme, $active }) =>
    $active ? '#00AED9' : theme.palette.common.white};
  &:hover {
    color: #00aed9;
  }
`;

export const Footer = ({ showCarliLink, customLinks = [] }: Props) => {
  const { pathname } = useLocation();

  return (
    <Root className='footer'>
      <NavBar>
        <StyledButton
          $active={Urls.SUPPORT === pathname}
          {...{ component: RouterLink, target: '_blank', to: Urls.SUPPORT }}
          data-qa='footer-contact'
        >
          Contact Support
        </StyledButton>
        <Divider orientation='vertical' variant='middle' />
        <StyledButton
          {...{ component: Link, target: '_blank', underline: 'none' }}
          href={Urls.REQUEST_ACCESS}
          data-qa='footer-request-access'
        >
          Request Access
        </StyledButton>
        {showCarliLink && (
          <>
            <Divider orientation='vertical' variant='middle' />
            <StyledButton
              {...{ component: Link, target: '_blank', underline: 'none' }}
              href={Urls.HEY_CARLI}
              data-qa='footer-hey-carli'
            >
              Hey Carli
            </StyledButton>
          </>
        )}
        {customLinks.map(({ id, onClick, label }) => (
          <Fragment key={id}>
            <Divider orientation='vertical' variant='middle' />
            <StyledButton
              {...{ component: Link, underline: 'none' }}
              onClick={onClick}
              data-qa={id}
            >
              {label}
            </StyledButton>
          </Fragment>
        ))}
      </NavBar>
    </Root>
  );
};
