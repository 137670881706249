import { ReactComponent as Caution } from '@irc/shared-assets/illustrations/caution-104px.svg';
import { styled } from '@mui/material/styles';

interface Props {
  className?: string;
}

export const CautionBubble = (props: Props) => {
  return (
    <Root className={props.className}>
      <Caution title='Error icon' />
    </Root>
  );
};

const Root = styled('div')`
  --size: 6.5rem;
  position: relative;
  background: ${({ theme }) => theme.palette.grey[50]};
  width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  margin-bottom: 3.5rem;
`;
