export type HostReportErrors = (error: unknown) => void;

/**
 * Throw an error in a new frame. This should be caught by the underlying platform
 * via `process.on('uncaughtException', () => ...)` or `window.addEventListener('error', () => ...)` and
 * reported.
 *
 * @param error
 */
export const rethrow: HostReportErrors = (error: unknown) => {
  setTimeout(() => {
    throw error;
  });
};

export const HostReportErrors = {
  rethrow
};
