import { useCallback, useMemo, useReducer } from 'react';

export interface FileReaderState {
  isLoading: boolean;
  result: string | ArrayBuffer | null;
  error: Error | null;
}

export const reducer = (
  state: FileReaderState,
  action: Partial<FileReaderState>
) => ({
  ...state,
  ...action
});

export const INITIAL_FILE_READER_STATE: FileReaderState = Object.freeze({
  isLoading: false,
  result: null,
  error: null
});

export type FileReaderMethod =
  | 'readAsArrayBuffer'
  | 'readAsBinaryString'
  | 'readAsDataURL'
  | 'readAsText';

type Props = {
  method?: FileReaderMethod;
};

export const useFileReader = ({ method = 'readAsDataURL' }: Props = {}) => {
  const [readerState, dispatch] = useReducer(
    reducer,
    INITIAL_FILE_READER_STATE
  );
  const reader = useMemo(() => new FileReader(), []);

  reader.onloadstart = () => {
    dispatch({ isLoading: true });
  };

  reader.onload = ({ target }: ProgressEvent<FileReader>) => {
    const result = target?.result;
    dispatch({ result });
  };

  reader.onloadend = () => {
    dispatch({ isLoading: false });
  };

  reader.onerror = (e: ProgressEvent<FileReader>) => {
    dispatch({ error: new Error('error reading file') });
  };

  const readFile = useCallback(
    (file: Blob) => {
      dispatch({ result: null });

      switch (method) {
        case 'readAsArrayBuffer':
          reader.readAsArrayBuffer(file);
          break;
        case 'readAsBinaryString':
          reader.readAsBinaryString(file);
          break;
        case 'readAsText':
          reader.readAsText(file);
          break;
        default:
          reader.readAsDataURL(file);
          break;
      }
    },
    [method, dispatch, reader]
  );

  const clear = useCallback(
    () => dispatch(INITIAL_FILE_READER_STATE),
    [dispatch]
  );

  return [readerState, readFile, clear] as const;
};
