import { PropsWithChildren } from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';

import {
  type Client,
  useAuthClient,
  useAuthState
} from '@irc/shared-services-auth';

type Props = NavigateProps & {
  onAuthRequired?: (client: Client) => void;
};

export const SecuredRedirect = ({
  children,
  onAuthRequired,
  ...props
}: PropsWithChildren<Props>) => {
  const client = useAuthClient();
  const { status } = useAuthState();

  if (status === 'unauthenticated') onAuthRequired?.(client);

  return status === 'authenticated' ? (
    <Navigate replace {...props} />
  ) : (
    <>{children}</>
  );
};
