import Spinner from '@carvana/showroom-spinner';
import { Typography } from '@irc/shared-components-typography';
import { styled } from '@mui/material/styles';

import { reduceStyle } from '../utils';

type Props = {
  className?: string;
  verticalAlign?: 'top' | 'center' | 'bottom';
  horizontalAlign?: 'left' | 'center' | 'right';
  size?: number;
  loadingText?: string;
};

const Root = styled('div')<Props>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ horizontalAlign }) => reduceStyle(horizontalAlign)};
  align-items: ${({ verticalAlign }) => reduceStyle(verticalAlign)};
`;

const LoadingText = styled(Typography)`
  margin-bottom: 25px;
`;

export const ProgressIndicator = ({
  verticalAlign,
  horizontalAlign,
  loadingText
}: Props) => (
  <Root
    role='progressbar'
    horizontalAlign={horizontalAlign}
    verticalAlign={verticalAlign}
  >
    {loadingText ? <LoadingText variant='headerL' text={loadingText} /> : null}
    <Spinner />
  </Root>
);
