import { RouteObject, RouterProvider } from 'react-router-dom';

import { Error, ErrorBoundary, ErrorRoute } from '@irc/shared-components-error';
import { authRoutes } from '@irc/shared-features-auth';
import { DEFAULT_AUTH_SIGNOUT } from '@irc/shared-services-auth';
import {
  createAppRouter,
  createRouteMetadata,
  RouteMetadata
} from '@irc/shared-utils-routing';

import { About } from '../pages/About';
import { Home } from '../pages/Home';
import { SignIn } from '../pages/SignIn';
import { SignOut } from '../pages/SignOut';
import { Support } from '../pages/Support';

export const appRouteMetadata: Record<string, RouteMetadata> = {
  Home: createRouteMetadata('/home'),
  About: createRouteMetadata('/about'),
  Support: createRouteMetadata('/support'),
  Error: createRouteMetadata(ErrorRoute.path),
  SignIn: createRouteMetadata('/'),
  SignOut: createRouteMetadata(DEFAULT_AUTH_SIGNOUT)
};

export const appRoutes: RouteObject[] = [
  {
    element: <SignIn />,
    path: appRouteMetadata['SignIn'].pattern,
    index: true
  },
  {
    element: <SignOut />,
    path: appRouteMetadata['SignOut'].pattern
  },
  {
    element: <Home />,
    path: appRouteMetadata['Home'].pattern
  },
  {
    element: <About />,
    path: appRouteMetadata['About'].pattern
  },
  {
    element: <Support />,
    path: appRouteMetadata['Support'].pattern
  },
  {
    element: <Error />,
    path: appRouteMetadata['Error'].pattern
  }
];

export const Router = () => {
  const router = createAppRouter([...authRoutes, ...appRoutes]);

  return (
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
};
