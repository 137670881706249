import {
  toast,
  ToastOptions,
  ToastPosition,
  TypeOptions
} from 'react-toastify';
import { v4 as uuidV4 } from 'uuid';

import { AlertColor } from '@mui/material/Alert';
import { SnackbarOrigin } from '@mui/material/Snackbar';

// TODO: Thin abstraction where we can define our own props
type NotificationOptions = {
  containerId?: string;
  toastId?: string;
};

/**
 * @deprecated use NotificationManager instead
 */
export const notification = {
  info: (message: string, opts?: NotificationOptions) =>
    toast.info(message, opts),
  warning: (message: string, opts?: NotificationOptions) =>
    toast.warning(message, opts),
  error: (message: string, opts?: NotificationOptions) =>
    toast.error(message, opts),
  success: (message: string, opts?: NotificationOptions) =>
    toast.success(message, opts),
  clear: (toastId?: string) => toast.dismiss(toastId)
};

export const mapPositionToOrigin = (
  position?: ToastPosition
): SnackbarOrigin => {
  if (!position) {
    return {
      vertical: 'bottom',
      horizontal: 'right'
    };
  }

  const [vertical, horizontal] = position.split('-');
  return {
    vertical: vertical as SnackbarOrigin['vertical'],
    horizontal: horizontal as SnackbarOrigin['horizontal']
  };
};

export const mapOriginToPosition = (origin?: SnackbarOrigin): ToastPosition => {
  return !origin ? 'bottom-right' : `${origin.vertical}-${origin.horizontal}`;
};

export const mapTypeToSeverity = (
  type: TypeOptions
): AlertColor | undefined => {
  if (type === 'default') return undefined;
  return type;
};

type NotificationConfiguration = {
  bindToContainer?: boolean;
  stackNotifications?: boolean;
};

export class NotificationManager {
  protected _containerId;
  protected _toastId;

  constructor(configuration?: NotificationConfiguration) {
    this._containerId = configuration?.bindToContainer ? uuidV4() : undefined;
    this._toastId = !configuration?.stackNotifications ? uuidV4() : undefined;
  }

  get containerId() {
    return this._containerId;
  }

  public info(message: string, options?: ToastOptions) {
    toast.info(message, this.createOptions(options));
  }

  public warning(message: string, options?: ToastOptions) {
    toast.warning(message, this.createOptions(options));
  }

  public error(message: string, options?: ToastOptions) {
    toast.error(message, this.createOptions(options));
  }

  public success(message: string, options?: ToastOptions) {
    toast.success(message, this.createOptions(options));
  }

  protected createOptions(additionalOptions?: ToastOptions): ToastOptions {
    return {
      containerId: this._containerId,
      toastId: this._toastId,
      updateId: this._toastId,
      ...additionalOptions
    };
  }
}
