import Axios from 'axios';

import { env } from '@irc/shared-utils-environment';
import { QueryFunctionContext } from '@tanstack/react-query';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: `${env.DOMAIN_APIK}/sce/location/graphql/`
});

export const client = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  options?: RequestInit['headers']
) => {
  return async (context?: QueryFunctionContext): Promise<TData> => {
    AXIOS_INSTANCE.interceptors.request = Axios.interceptors.request;
    AXIOS_INSTANCE.interceptors.response = Axios.interceptors.response;

    return AXIOS_INSTANCE({
      signal: context?.signal,
      method: 'POST',
      data: {
        query,
        variables
      },
      paramsSerializer: {
        indexes: true
      }
    }).then(({ data }) => data.data ?? data);
  };
};
