import { IsEqualCustomizer, isEqualWith } from 'lodash';

export type Comparer<T> = (x: T, y: T) => boolean;

const EXACT_COMPARISON = (x: string, y: string) => {
  return x === y;
};

const IGNORE_CASE_COMPARISON = (x: string, y: string) => {
  const xx = x.toUpperCase();
  const yy = y.toUpperCase();

  return xx === yy;
};

export const StringComparison = {
  Exact: EXACT_COMPARISON,
  IgnoreCase: IGNORE_CASE_COMPARISON
};

export const isEqual = (
  value: string,
  other: string | undefined,
  comparer?: IsEqualCustomizer
) => {
  if (comparer == null) {
    comparer = StringComparison.Exact;
  }

  if (other == null) {
    return false;
  } else if (value.length !== other.length) {
    return false;
  }

  return isEqualWith(value, other, comparer);
};
