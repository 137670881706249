import EventEmitter from 'eventemitter3';
import {
  SigninPopupArgs,
  SigninRedirectArgs,
  SigninSilentArgs,
  User as OidcUser,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore
} from 'oidc-client-ts';

import { Authenticator } from '../models/Authenticator';
import { User } from '../models/User';
import {
  Configuration,
  EventHandlers,
  SigninState,
  SigninType,
  SignoutState,
  SignoutType
} from '../types';
import {
  DEFAULT_AUTH_SIGNIN_CALLBACK,
  DEFAULT_AUTH_SIGNOUT_CALLBACK,
  DEFAULT_AUTH_SILENT_RENEW_CALLBACK,
  joinScopes
} from '../utils';

export class CodeAuthenticator implements Authenticator {
  private _instance!: UserManager;
  private _eventHandlers: EventHandlers<OidcUser>;
  private _defaultSettings: Partial<UserManagerSettings> = {
    redirect_uri: `${window.location.origin}${DEFAULT_AUTH_SIGNIN_CALLBACK}`,
    silent_redirect_uri: `${window.location.origin}${DEFAULT_AUTH_SILENT_RENEW_CALLBACK}`,
    post_logout_redirect_uri: `${window.location.origin}${DEFAULT_AUTH_SIGNOUT_CALLBACK}`,
    revokeTokensOnSignout: true,
    includeIdTokenInSilentRenew: true,
    automaticSilentRenew: true,
    filterProtocolClaims: false,
    loadUserInfo: true,
    staleStateAgeInSeconds: 60,
    silentRequestTimeoutInSeconds: 10,
    checkSessionIntervalInSeconds: 30,
    accessTokenExpiringNotificationTimeInSeconds: 30,
    prompt: 'login',
    acr_values: 'idp:okta',
    response_type: 'code',
    query_status_response_type: 'code',
    scope: '',
    popupWindowFeatures: {
      location: false,
      toolbar: false,
      width: 800,
      height: 800,
      left: 25,
      top: 25
    },
    userStore: new WebStorageStateStore({ store: window.localStorage })
  };

  constructor(configuration: Configuration, emitEvent: EventEmitter['emit']) {
    this._instance = new UserManager({
      ...this._defaultSettings,
      authority: configuration.authority,
      client_id: configuration.clientId,
      scope: joinScopes(configuration.scope),
      redirect_uri:
        configuration.redirectUri || this._defaultSettings.redirect_uri || '',
      post_logout_redirect_uri:
        configuration.signOutRedirectUri ||
        this._defaultSettings.post_logout_redirect_uri,
      popupWindowFeatures:
        configuration.popupWindowFeatures ||
        this._defaultSettings.popupWindowFeatures,
      silent_redirect_uri:
        configuration.silentRedirectUri ||
        this._defaultSettings.silent_redirect_uri
    });

    this._eventHandlers = {
      onUserLoaded: (rawUser: OidcUser) => {
        console.debug('CodeAuthenticator:', 'onUserLoaded', { rawUser });
        emitEvent('userLoaded', this.createUser(rawUser));
      },
      onSilentRenewError: (err: Error) => {
        console.debug('CodeAuthenticator:', 'onSilentRenewError');
        emitEvent('silentRenewError', err);
      },
      onAccessTokenExpired: () => {
        console.debug('CodeAuthenticator:', 'onAccessTokenExpired');
        emitEvent('accessTokenExpired');
      },
      onAccessTokenExpiring: () => {
        console.debug('CodeAuthenticator:', 'onAccessTokenExpiring');
        emitEvent('accessTokenExpiring');
      },
      onUserUnloaded: () => {
        console.debug('CodeAuthenticator:', 'onUserUnloaded');
        emitEvent('userUnloaded');
      },
      onUserSignedOut: () => {
        console.debug('CodeAuthenticator:', 'onUserSignedOut');
        emitEvent('userSignedOut');
      }
    };

    this._instance.events.addUserLoaded(this._eventHandlers.onUserLoaded);
    this._instance.events.addSilentRenewError(
      this._eventHandlers.onSilentRenewError
    );
    this._instance.events.addAccessTokenExpired(
      this._eventHandlers.onAccessTokenExpired
    );
    this._instance.events.addAccessTokenExpiring(
      this._eventHandlers.onAccessTokenExpiring
    );
    this._instance.events.addUserUnloaded(this._eventHandlers.onUserUnloaded);
    this._instance.events.addUserSignedOut(this._eventHandlers.onUserSignedOut);
  }

  public removeEventListeners() {
    this._instance.events.removeUserLoaded(this._eventHandlers.onUserLoaded);
    this._instance.events.removeSilentRenewError(
      this._eventHandlers.onSilentRenewError
    );
    this._instance.events.removeAccessTokenExpired(
      this._eventHandlers.onAccessTokenExpired
    );
    this._instance.events.removeAccessTokenExpiring(
      this._eventHandlers.onAccessTokenExpiring
    );
    this._instance.events.removeUserUnloaded(
      this._eventHandlers.onUserUnloaded
    );
    this._instance.events.removeUserSignedOut(
      this._eventHandlers.onUserSignedOut
    );
  }

  public async getUser() {
    const rawUser = await this._instance.getUser();
    return rawUser ? this.createUser(rawUser) : null;
  }

  public async signin(type: SigninType, state: SigninState = {}) {
    const nextState = Array.isArray(state.scope)
      ? { ...state, scope: state.scope.join(' ') }
      : state;

    try {
      this.clearStaleState();

      switch (type) {
        case 'silent':
          await this._instance.signinSilent(nextState as SigninSilentArgs);
          break;
        case 'redirect':
          await this._instance.signinRedirect(nextState as SigninRedirectArgs);
          break;
        case 'popup':
          await this._instance.signinPopup(nextState as SigninPopupArgs);
          break;
      }
    } catch (err) {
      console.error(
        'CodeAuthenticator:',
        'Failed to initiate signin request',
        err
      );
    }
  }

  public async signinCallback() {
    try {
      const rawUser = await this._instance.signinCallback();
      return rawUser ? this.createUser(rawUser) : null;
    } catch (err) {
      console.error(
        'CodeAuthenticator:',
        'Failed to initiate signin callback',
        err
      );
      return null;
    }
  }

  public async signout(type: SignoutType, state?: SignoutState) {
    try {
      switch (type) {
        case 'redirect':
          await this._instance.signoutRedirect(state);
          break;
        case 'popup':
          await this._instance.signoutPopup(state);
          break;
      }
    } catch (err) {
      console.error(
        'CodeAuthenticator:',
        'Failed to initiate signout request',
        err
      );
    }
  }

  public async signoutCallback(type?: SignoutType, url?: string) {
    try {
      switch (type) {
        case 'redirect':
          return (await this._instance.signoutRedirectCallback(url)).userState;
        case 'popup':
          return await this._instance.signoutPopupCallback(url);
        default:
          return await this._instance.signoutCallback(url);
      }
    } catch (err) {
      console.error(
        'CodeAuthenticator:',
        'Failed to initiate signout callback',
        err
      );
      return;
    }
  }

  private async clearStaleState(): Promise<void> {
    try {
      await this._instance.clearStaleState();
    } catch (err) {
      console.error('CodeAuthenticator:', 'Failed to clear state', err);
    }
  }

  private createUser(rawUser: OidcUser | null) {
    console.debug('CodeAuthenticator:', 'createUser', { rawUser });

    return new User({
      id_token: rawUser?.id_token,
      session_state: rawUser?.session_state,
      access_token: rawUser?.access_token || '',
      refresh_token: rawUser?.refresh_token,
      token_type: rawUser?.token_type || '',
      scope: rawUser?.scope,
      profile: {
        ...rawUser?.profile,
        sub: rawUser?.profile?.sub || '',
        iss: rawUser?.profile?.iss || '',
        aud: rawUser?.profile?.aud || '',
        exp: rawUser?.profile?.exp || 0,
        iat: rawUser?.profile?.iat || 0,
        nonce: rawUser?.profile?.nonce?.toString() || ''
      },
      expires_at: rawUser?.expires_at,
      userState: rawUser?.state
    });
  }
}
