import { CautionBubble } from './CautionBubble';
import { CenteredVerticalLayout } from './CenteredVerticalLayout';
import { Header, Summary } from './styles';

export const NotFound = () => {
  return (
    <CenteredVerticalLayout>
      <Header>Oops, page not found!</Header>
      <CautionBubble />
      <Summary>
        The link may be broken, or the page may have been removed.
        <br />
        You can always go back and try again
      </Summary>
    </CenteredVerticalLayout>
  );
};
