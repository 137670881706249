import { EventEmitter } from 'eventemitter3';

import { CodeAuthenticator } from '../authenticators/CodeAuthenticator';
import { Configuration, SigninState, SignoutState } from '../types';
import { getMissingScopes, joinScopes } from '../utils';

import { Authenticator } from './Authenticator';
import { User } from './User';

const MAX_SCOPE_LENGTH = 300;

export abstract class Client<
  C extends Configuration = Configuration
> extends EventEmitter {
  protected _authenticator: Authenticator;
  private scope: string[];

  constructor(configuration: C) {
    super();

    this._authenticator = new CodeAuthenticator(
      configuration,
      this.emit.bind(this)
    );
    this.scope = configuration.scope;
  }

  public async updateScope(newScopes: string[] | string) {
    try {
      return await this.signIn(
        { scope: Array.isArray(newScopes) ? newScopes.join(' ') : newScopes },
        true
      );
    } catch (e) {
      const error =
        newScopes && newScopes.length >= MAX_SCOPE_LENGTH
          ? new Error(
              `Auth scopes requested exceed maximum length of ${MAX_SCOPE_LENGTH} characters`
            )
          : e instanceof Error
          ? e
          : new Error(e as string);

      throw error;
    }
  }

  public async getUser() {
    let user = await this._authenticator.getUser();
    if (!user) {
      return user;
    }

    const newScopes = getMissingScopes(user.scopes, this.scope);
    if (newScopes.length) {
      user = (await this.updateScope(joinScopes(this.scope))) ?? user;
    }

    return user;
  }

  public abstract signIn(
    state?: SigninState,
    silent?: boolean
  ): Promise<User | void>;

  public abstract signOut(state?: SignoutState): Promise<void>;

  public signInCallback() {
    return this._authenticator.signinCallback();
  }

  public signOutCallback(url?: string) {
    return this._authenticator.signoutCallback(undefined, url);
  }
}
