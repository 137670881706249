import { PropsWithChildren } from 'react';

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { getErrorMessage } from './utils';

type ClientProps = {
  onError?: (error?: string) => void;
};

//https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
export const createQueryClient = (props?: ClientProps) => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
      }
    },
    queryCache: new QueryCache({
      onError: (error) => {
        props?.onError?.(getErrorMessage(error as any));
      }
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        props?.onError?.(getErrorMessage(error as any));
      }
    })
  });
};

type Props = {
  client: QueryClient;
};

export const QueryProvider = ({
  client,
  children
}: PropsWithChildren<Props>) => (
  <QueryClientProvider client={client}>
    <ReactQueryDevtools initialIsOpen={false} />
    {children}
  </QueryClientProvider>
);
