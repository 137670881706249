import merge from 'lodash/merge';

import sharedTheme, { createResponsiveTheme } from '@irc/shared-theme';

export const createOverrides = ({
  palette,
  breakpoints,
  components = {}
}: typeof sharedTheme): (typeof sharedTheme)['components'] =>
  merge(components, {
    MuiCssBaseline: {
      styleOverrides: `
        ${components.MuiCssBaseline?.styleOverrides}
        html {
          height: 100%;
          overflow: hidden;
        }
        body {
          height: 100%;
          overflow: auto;
        }
        #root {
          height: 100%;
        }
      `
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontWeight: 700
        },
        h5: {
          fontWeight: 700
        },
        h6: {
          fontWeight: 700
        },
        /* only headings are working with responsiveFontSizes, so this is a starting point for subtitles */
        subtitle1: {
          fontSize: '1.25rem',
          [breakpoints.down('sm')]: {
            fontSize: '1rem'
          }
        },
        subtitle2: {
          fontSize: '1.1rem',
          [breakpoints.down('sm')]: {
            fontSize: '.9rem'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderColor: palette.divider
        }
      }
    }
  });

sharedTheme.components = createOverrides(sharedTheme);

const theme = createResponsiveTheme(sharedTheme);
export { theme };
